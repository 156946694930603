import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import { UserDataGateway } from '../../gateways/userDataGateway.interface';
import * as actionCreators from './actionCreators';

export const getUserFeedsUseCase =
    (): ThunkResult<void> =>
    async (dispatch, getState, { userDataGateway }: { userDataGateway: UserDataGateway }) => {
        dispatch(actionCreators.Actions.userFeedsFetching());
        const result = await userDataGateway.getUserFeeds(getState().auth.user.language);

        if (isRight(result)) {
            dispatch(actionCreators.Actions.userFeedsRetrieved(result.right));
        } else {
            dispatch(actionCreators.Actions.userFeedsFailed());
        }
    };
