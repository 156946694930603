import { ActionsUnion, createAction } from '../../../redux/customActions';
import { UserStats } from '../../models/userStatsModel';

export const Actions = {
    userStatsFetching: () => createAction('userStats/userStatsRequested'),
    userStatsFailed: () => createAction('userStats/userStatsRequestFailed'),
    userStatsRetrieved: (userStats: UserStats) =>
        createAction('userStats/userStatsReceived', { userStats }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
