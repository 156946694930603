import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';
import { UserInfoGateway } from '../../gateways/userInfoGateway.interface';
import { UpdatableUserInfo } from '../../models/userModel';
import { getUserInfoUseCase } from '../getUserInfo/getUserInfoUseCase';

export const updateUserInfoUseCase =
    (updatableUserInfo: Partial<UpdatableUserInfo>): ThunkResult<void> =>
    async (dispatch, getState, { userInfoGateway }: { userInfoGateway: UserInfoGateway }) => {
        dispatch(actionCreators.Actions.userInfoUpdateRequested());

        const resultUpdate = await userInfoGateway.updateUserInfo(updatableUserInfo);

        if (isRight(resultUpdate)) {
            dispatch(actionCreators.Actions.userInfoUpdateSucceed());
            dispatch(getUserInfoUseCase());
        } else {
            dispatch(actionCreators.Actions.userInfoUpdateFailed());
        }
    };
