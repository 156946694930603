import { ActionsUnion, createAction } from '../../../redux/customActions';
import { OfficeLanguage } from '../../config/appConfig';

export const Actions = {
    userLangUpdating: () => createAction('userInfo/userInfoUpdateRequested'),
    userLangFailed: () => createAction('userInfo/userInfoUpdateRequestFailed'),
    userLangSet: (language: OfficeLanguage) =>
        createAction('userInfo/userLangUpdateRequestSucceed', { language }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
