import { ActionsUnion, createAction } from '../../../redux/customActions';
import { UserFeeds } from '../../models/userFeedsModel';

export const Actions = {
    userFeedsFetching: () => createAction('userFeeds/userFeedsRequested'),
    userFeedsFailed: () => createAction('userFeeds/userFeedsRequestFailed'),
    userFeedsRetrieved: (userFeeds: UserFeeds) =>
        createAction('userFeeds/userFeedsReceived', { userFeeds }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
