import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Col, Container, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { AutobizButton, AutobizIcon, AutobizSectionTitle } from 'autobiz-strap';
import { t } from 'autobiz-translate';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Form, Field } from 'react-final-form';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js/types';
import Header from '../Header/Header';
import { getUserSelector } from '../../view-models-generators/userSelector';
import { updateUserInfoUseCase } from '../../../../hexagon/usecases/updateUserInfo/updateUserInfoUseCase';
import { UpdatableUserInfo } from '../../../../hexagon/models/userModel';
import 'react-phone-number-input/style.css';
import AuthSpinner from '../AuthSpinner/AuthSpinner';

const UserProfile: React.FC = () => {
    const dispatch = useDispatch();
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const { user, updateStatus, status } = useSelector(getUserSelector);
    const { email, phone, language } = user;

    // const remove AZ
    const canAccessUserProfilePage = ['STLA', 'AZ'].includes(user.provider);

    useEffect(() => {
        if (updateStatus === 'succeeded' || updateStatus === 'failed') setAlertOpen(true);
    }, [dispatch, updateStatus]);

    const onSubmit = async (props: Partial<UpdatableUserInfo>) => {
        let userInfo = {
            id: user.id,
        };
        if (email !== props.email) {
            userInfo = { ...userInfo, ...{ email: props.email } };
        }

        if (phone !== props.phone) {
            userInfo = { ...userInfo, ...{ phone: props.phone } };
        }
        dispatch(updateUserInfoUseCase({ ...userInfo, ...{ id: user.id } }));
        setAlertOpen(false);
    };

    if (updateStatus === 'succeeded') {
        window.location.reload();
    }

    const validationSchema: Yup.SchemaOf<any> = Yup.object().shape({
        email: Yup.string().email().required(),
        phone: Yup.string().required().min(10),
        language: Yup.string().required(),
    });

    const handleGoDashboard = () => {
        navigate('/dashboard');
    };

    const validate = (values: any): Promise<any> =>
        validationSchema
            .validate(values, { abortEarly: false })
            .then(() => setCanSubmit(true))
            .catch((error: Yup.ValidationError) => {
                const errors: { [value: string]: string } = {};
                error.inner.forEach((e) => {
                    if (e.path) {
                        errors[e.path] = e.message;
                    }
                });
                setCanSubmit(false);

                return errors;
            });

    let defaultPhonePrefix: CountryCode = 'FR';
    const availablePhonePrefix = [
        'FR',
        'ES',
        'IT',
        'PT',
        'GB',
        'DE',
        'BE',
        'LU',
        'AT',
        'NL',
    ] as CountryCode[];

    const phonePrefixCountryLabel = {
        FR: t('france'),
        ES: t('spain'),
        IT: t('italy'),
        PT: t('portugal'),
        GB: t('greatBritain'),
        DE: t('germany'),
        BE: t('belgium'),
        LU: t('luxembourg'),
        AT: t('austria'),
        NL: t('netherlands'),
    };

    if (availablePhonePrefix.includes(user.country as CountryCode)) {
        defaultPhonePrefix = user.country.toUpperCase() as CountryCode;
    }
    if (status === 'loading') return <Spinner />;
    if (canAccessUserProfilePage === false) {
        return (
            <>
                <Header />
                <Container className="hasFixedHeader">
                    <Row className="my-5 sticky-preferences-ctas justify-content-center text-center">
                        <Col xs="12" md="6" className="mb-2">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: t('messageProfilePageNotAuthorized') || '',
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="6" md="3">
                            <AutobizButton
                                type="submit"
                                color="primary"
                                block
                                onClick={handleGoDashboard}
                            >
                                {t('ctaDashboard')}
                            </AutobizButton>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    return (
        <>
            <Header />
            <Container className="hasFixedHeader" />

            <Container>
                {updateStatus === 'succeeded' && (
                    <Alert
                        color="success"
                        className="mt-4"
                        fade
                        isOpen={alertOpen}
                        toggle={() => setAlertOpen(false)}
                    >
                        {t('updateUserProfileSucceed')}
                    </Alert>
                )}

                {updateStatus === 'failed' && (
                    <Alert
                        color="danger"
                        className="mt-4"
                        fade
                        isOpen={alertOpen}
                        toggle={() => setAlertOpen(false)}
                    >
                        {t('updateUserProfileFailed')}
                    </Alert>
                )}
                <Row>
                    <Col>
                        <div className="mt-4">
                            <AutobizSectionTitle>
                                <AutobizIcon name="User" /> {t('profilePageTitle')}
                            </AutobizSectionTitle>
                            <p>{t('explanationProfilePage')}</p>

                            <Form
                                onSubmit={onSubmit}
                                validate={validate}
                                initialValues={{ email, phone, language }}
                                render={({ handleSubmit, pristine }) => (
                                    <form onSubmit={handleSubmit} className="mt-5">
                                        <div>
                                            <Field
                                                name="email"
                                                render={({ input, meta }) => (
                                                    <FormGroup
                                                        className={
                                                            meta.error && meta.touched
                                                                ? 'row form-group-error'
                                                                : 'row'
                                                        }
                                                    >
                                                        <Col sm={6} lg={8}>
                                                            <Label>{t('email')}</Label>
                                                        </Col>

                                                        <Col sm="6" lg={4} className="userInputs">
                                                            <input
                                                                type="text"
                                                                {...input}
                                                                disabled={
                                                                    updateStatus === 'loading'
                                                                }
                                                                value={input.value}
                                                                className={`form-control text-right ${
                                                                    meta.error &&
                                                                    meta.touched &&
                                                                    'error-control'
                                                                }`}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name="phone"
                                                render={({ input, meta }) => (
                                                    <FormGroup
                                                        className={
                                                            meta.error && meta.touched
                                                                ? 'row form-group-error'
                                                                : 'row'
                                                        }
                                                    >
                                                        <Col sm={6} lg={8}>
                                                            <Label>{t('phoneNumber')}</Label>
                                                        </Col>

                                                        <Col sm="6" lg={4} className="userInputs">
                                                            <PhoneInput
                                                                international={false}
                                                                defaultCountry={defaultPhonePrefix}
                                                                disabled={
                                                                    updateStatus === 'loading'
                                                                }
                                                                labels={phonePrefixCountryLabel}
                                                                countries={availablePhonePrefix}
                                                                {...input}
                                                                value={input.value}
                                                                className={`form-control text-right ${
                                                                    meta.error &&
                                                                    meta.touched &&
                                                                    'error-control'
                                                                }`}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                            />
                                        </div>
                                        {/* <div>
                                            <Field
                                                name="language"
                                                render={({ input, meta }) => (
                                                    <FormGroup
                                                        className={
                                                            meta.error && meta.touched
                                                                ? 'row form-group-error'
                                                                : 'row'
                                                        }
                                                    >
                                                        <Col sm={6} lg={8}>
                                                            <Label>{t('languageByDefault')}</Label>
                                                        </Col>

                                                        <Col sm="6" lg={4}>
                                                            <select
                                                                className={`form-control  ${
                                                                    meta.error &&
                                                                    meta.touched &&
                                                                    'error-control'
                                                                }`}
                                                            >
                                                                {officeLanguages.map(
                                                                    ({ id, language_iso2 }) => (
                                                                        <option
                                                                            key={id}
                                                                            value={id}
                                                                            selected={
                                                                                language_iso2 ===
                                                                                input.value
                                                                            }
                                                                        >
                                                                            {t(language_iso2)}{' '}
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </select>
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                            />
                                        </div> */}
                                        <AutobizButton
                                            type="submit"
                                            color="primary"
                                            disabled={
                                                !canSubmit || updateStatus === 'loading' || pristine
                                            }
                                            className={`mx-auto mt-5 ${
                                                !canSubmit || updateStatus === 'loading'
                                            }`}
                                        >
                                            {updateStatus === 'loading' ? (
                                                <Spinner />
                                            ) : (
                                                t('valider')
                                            )}
                                        </AutobizButton>
                                    </form>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default withAuthenticationRequired(UserProfile, {
    returnTo: '/userprofile',
    onRedirecting: () => <AuthSpinner />,
});
