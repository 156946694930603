import React from 'react';
import { Card, CardBody, CardFooter, Col } from 'reactstrap';
import { AutobizLogo, AutobizButton } from 'autobiz-strap';
import { t } from 'autobiz-translate';
import { useSelector } from 'react-redux';
import { ProductName } from '../../../../hexagon/models/productModel';
import { appDefaultLang, newProducts } from '../../../../hexagon/config/appConfig';
import { getUserSelector } from '../../view-models-generators/userSelector';

interface IProduct {
    product: { name: ProductName; link: string };
    type: 'demo' | 'connect';
}

const ProductCard: React.FC<IProduct> = ({ product, type }) => {
    const { user } = useSelector(getUserSelector);
    const language = user.language || appDefaultLang;
    return (
        <Col key={product.name} lg={3} className="my-3">
            <Card className="h-100">
                <CardBody>
                    {newProducts.includes(product.name) && <div className="info">{t('new')}</div>}
                    <AutobizLogo
                        lang={language}
                        country={user.country}
                        product={product.name}
                        withBrandNameOnMobile
                        withBrandNameOnDesktop
                    />
                    <div className="text-center">{t(`${product.name}_product_description`)}</div>
                </CardBody>
                <CardFooter className="border-0">
                    {type === 'demo' && (
                        <AutobizButton
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(product.link, '_blank')?.focus();
                            }}
                            color="primary"
                            block
                        >
                            {t('demo')}
                        </AutobizButton>
                    )}

                    {type === 'connect' && (
                        <AutobizButton
                            type="button"
                            color="primary"
                            block
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(product.link, '_blank')?.focus();
                            }}
                        >
                            {t('access')}
                        </AutobizButton>
                    )}
                </CardFooter>
            </Card>
        </Col>
    );
};

export default ProductCard;
