import { User } from '../../../../../hexagon/models/userModel';

export const aAutobizUser: User = {
    id: '123',
    firstName: 'John',
    lastName: 'Autobiz',
    country: 'FR',
    email: 'john@autobiz.com',
    phone: '+33652527838',
    language: 'fr',
    subscriptionId: 111,
    canEditPreferences: true,
    provider: 'AZ',
    userApps: [{ name: 'autobizCarcheck', link: '#' }],
};

export const aStellantisUser: User = {
    id: '123',
    firstName: 'John',
    lastName: 'Stellantis',
    country: 'FR',
    email: 'john@psa.com',
    phone: '+33652527838',
    language: 'fr',
    subscriptionId: 111,
    canEditPreferences: true,
    provider: 'STLA',
    userApps: [{ name: 'autobizCarcheck', link: '#' }],
};
