import { UserStats } from '../../../hexagon/models/userStatsModel';
import { AppState, FetchStatus } from '../../../redux/appState';

export interface UserStatsVM {
    status: FetchStatus;
    data: UserStats;
}

export const getUserStatsSelector = (state: AppState): UserStatsVM => ({
    status: state.userStats.status,
    data: state.userStats.data,
});
