import React from 'react';
import { Spinner } from 'reactstrap';
import { ReactComponent as Logo } from '../../../../assets/loader.svg';

const AuthSpinner = () => (
    <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
        <Logo style={{ width: '17rem', height: '10rem' }} />
        <div className="d-flex" style={{ gap: '1rem' }}>
            {[...Array(3)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Spinner key={i} size="sm" type="grow" color="secondary" />
            ))}
        </div>
    </div>
);

export default AuthSpinner;
