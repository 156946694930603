import { UserFeeds } from '../../../hexagon/models/userFeedsModel';
import { AppState, FetchStatus } from '../../../redux/appState';

export interface UserFeedsVM {
    status: FetchStatus;
    data: UserFeeds;
    count: number;
}

export const getUserFeedsSelector = (state: AppState): UserFeedsVM => ({
    status: state.userFeeds.status,
    data: state.userFeeds.data,
    count: state.userFeeds.data.totalFeeds,
});
