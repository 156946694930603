import frFlag from './assets/flags/fr.svg';
import esFlag from './assets/flags/es.svg';
import itFlag from './assets/flags/it.svg';
import ptFlag from './assets/flags/pt.svg';
import enFlag from './assets/flags/en.svg';
import nlFlag from './assets/flags/nl.svg';
import deFlag from './assets/flags/de.svg';
import { OfficeLanguage, OfficeCountry } from './hexagon/config/appConfig';

export const flags = {
    fr: frFlag,
    en: enFlag,
    es: esFlag,
    it: itFlag,
    pt: ptFlag,
    nl: nlFlag,
    de: deFlag,
};

export const contactUsEmails = {
    fr: 'supportclientb2b@autobiz.com',
    it: 'supportoclienti@autobiz.com',
    es: 'contactar@autobiz.com',
    uk: 'support@autobiz.com',
    pt: 'contactar@autobiz.com',
    de: 'support@autobiz.com',
    nl: 'support@autobiz.com',
    at: 'support@autobiz.com',
    cz: 'support@autobiz.com',
    ch: 'support@autobiz.com',
    ro: 'support@autobiz.com',
    se: 'support@autobiz.com',
    dk: 'support@autobiz.com',
    pl: 'support@autobiz.com',
    gr: 'support@autobiz.com',
    ie: 'support@autobiz.com',
    hu: 'support@autobiz.com',
    fi: 'support@autobiz.com',
    sk: 'support@autobiz.com',
    no: 'support@autobiz.com',
    be: 'support@autobiz.com',
    lu: 'support@autobiz.com',
};

export const getCurrentPath = () => `/${window.location.pathname.split('/').slice(2).join('/')}`;

export const getFlag = (language: OfficeLanguage) => flags[language];

export const getContactEmail = (country: OfficeCountry) => contactUsEmails[country];

export const getLinkLanguage = (language: string): string => {
    switch (language) {
        case 'nl':
        case 'de':
            return 'en';
        default:
            return language;
    }
};
