import { WordpressPostDto } from '../dtos/wordpressPostDto';
import { Post } from '../../../../../hexagon/models/postModel';
import { Mapper } from '../../../../../hexagon/infra/Mapper';

export class PostMapper implements Mapper<Post> {
    static toModel(wordpressPostDto: WordpressPostDto): Post {
        const image = wordpressPostDto.yoast_head_json.og_image;
        return {
            id: wordpressPostDto.id.toString(),
            title: wordpressPostDto.title.rendered,
            excerpt: wordpressPostDto.excerpt.rendered.replace(/(<([^>]+)>)/gi, ''),
            link: wordpressPostDto.link,
            thumbnail: image ? image[0]?.url : undefined,
        };
    }
}
