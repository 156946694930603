export const getCanEditPrefrences = (apiUserRoles: string[], carcheckRoles: string[]): boolean => {
    if (apiUserRoles.includes('isAccountAdmin')) {
        if (apiUserRoles.includes('buyerCarcheck')) {
            if (
                carcheckRoles.includes('manager') ||
                carcheckRoles.includes('admin') ||
                carcheckRoles.includes('superAdmin')
            ) {
                return true;
            }
            return false;
        }
        return true;
    }
    return false;
};
