import { UserStats } from '../../../../../hexagon/models/userStatsModel';

export const someUserStats: UserStats = {
    sourcing: {
        autobizClic2Sell: {
            statisticHBSSMSSentNumber: 16,
            statisticReceivedAppraisalNumber: 10,
        },
    },
    inspection: {
        autobizCarcheck: {
            statisticHBSCompletedAppraisalNumber: 10,
            statisticPhysicalCompletedAppraisalNumber: 20,
            statisticAverageAppraisalTimePeriod: 30,
            statisticAverageCosts: 40,
        },
    },
    pricing: {
        autobizCarcheck: {
            statisticAveragePricingTimePeriod: 50,
            statisticPricingRPI: 60,
        },
    },
    remarketing: {
        autobizTrade: {
            statisticVehicleForSaleNumber: 70,
            statisticReceivedOfferNumber: 80,
        },
    },
};
