import { useState, useEffect } from 'react';

const useDeviceType = () => {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== 'undefined') {
            // Handler to call on window resize
            const handleResize = () => {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            };

            // Add event listener
            window.addEventListener('resize', handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener('resize', handleResize);
        }

        return () => {};
    }, []); // Empty array ensures that effect is only run on mount

    let deviceType = '';

    if (windowSize.width) {
        if (windowSize.width < 768) deviceType = 'mobile';
        else if (windowSize.width < 1199) deviceType = 'tablet';
        else if (windowSize.width > 1200) deviceType = 'desktop';
    }

    return deviceType;
};

export default useDeviceType;
