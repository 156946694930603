import { left, right } from 'fp-ts/Either';
import { PostGateway } from '../../../../hexagon/gateways/postGateway.interface';
import { ApiResponse } from '../../../../hexagon/infra/ApiResponse';
import { Post } from '../../../../hexagon/models/postModel';

export class InMemoryPostGateway implements PostGateway {
    private postsList = [] as Post[];

    requestAllPosts(lang: string): Promise<ApiResponse<Post[]>> {
        if (this.postsList && lang) {
            return Promise.resolve(right(this.postsList));
        }
        return Promise.resolve(left({ code: '401', message: 'no posts' }));
    }

    feedWith(users: Post[]) {
        this.postsList = users;
    }
}
