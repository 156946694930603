import { AnyAction, combineReducers } from 'redux';
import { User } from '../../hexagon/models/userModel';
import { FetchStatus } from '../appState';

const initialState = {
    user: {} as User,
};

export const updateStatus = (state: FetchStatus = 'idle', action: AnyAction) => {
    if (action.type === 'userInfo/userInfoUpdateRequested') return 'loading';
    if (action.type === 'userInfo/userInfoUpdateRequestSucceed') return 'succeeded';
    if (action.type === 'userInfo/userLangUpdateRequestSucceed') return 'succeeded';
    if (action.type === 'userInfo/userInfoUpdateRequestFailed') return 'failed';
    return state;
};

export const status = (state: FetchStatus = 'idle', action: AnyAction) => {
    if (action.type === 'userInfo/userInfoRequested') return 'loading';
    if (action.type === 'userInfo/userInfoReceived') return 'succeeded';
    if (action.type === 'userInfo/userInfoRequestFailed') return 'failed';
    return state;
};

export const tokenStatus = (state: FetchStatus = 'idle', action: AnyAction) => {
    if (action.type === 'userInfo/tokenRequested') return 'loading';
    if (action.type === 'userInfo/tokenReceived') return 'succeeded';
    if (action.type === 'userInfo/tokenRequestFailed') return 'failed';
    return state;
};

export const user = (state: User = initialState.user, action: AnyAction) => {
    if (action.type === 'userInfo/userInfoReceived') {
        return action.payload.user;
    }

    if (action.type === 'userInfo/userLangUpdateRequestSucceed') {
        return {
            ...state,
            language: action.payload.language,
        };
    }

    return state;
};

export const userReducer = combineReducers({
    status,
    updateStatus,
    tokenStatus,
    user,
});
