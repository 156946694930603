import { left, right } from 'fp-ts/Either';
import { BaseApi } from '../../../../hexagon/infra/BaseApi';
import { API_OFFICE_URL } from '../../../../config';
import { ApiResponse } from '../../../../hexagon/infra/ApiResponse';
import { UserDataGateway } from '../../../../hexagon/gateways/userDataGateway.interface';
import { UserFeeds } from '../../../../hexagon/models/userFeedsModel';
import { OfficeLanguage } from '../../../../hexagon/config/appConfig';
import { UserStats } from '../../../../hexagon/models/userStatsModel';

export class AutobizOfficeApiUserDataGateway extends BaseApi implements UserDataGateway {
    setToken(token: string): ApiResponse<boolean> {
        if (token) {
            this.setBearerToken(token);
            return right(true);
        }

        return left({ code: '401', message: 'not authorized' });
    }

    async getUserFeeds(language: OfficeLanguage): Promise<ApiResponse<UserFeeds>> {
        try {
            const response = await this.get(`${API_OFFICE_URL}/userFeeds`, {
                params: {
                    language,
                },
            });
            return right(response.data);
        } catch (error) {
            return left({ code: '401', message: 'retrieve_feeds_failed' });
        }
    }

    async getUserStats(): Promise<ApiResponse<UserStats>> {
        try {
            const response = await this.get(`${API_OFFICE_URL}/userStats`, {});
            return right(response.data);
        } catch (error) {
            return left({ code: '401', message: 'retrieve_stats_failed' });
        }
    }
}
