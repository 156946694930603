import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import { UserDataGateway } from '../../gateways/userDataGateway.interface';
import * as actionCreators from './actionCreators';

export const getUserStatsUseCase =
    (): ThunkResult<void> =>
    async (dispatch, getState, { userDataGateway }: { userDataGateway: UserDataGateway }) => {
        dispatch(actionCreators.Actions.userStatsFetching());
        const result = await userDataGateway.getUserStats();
        if (isRight(result)) {
            dispatch(actionCreators.Actions.userStatsRetrieved(result.right));
        } else {
            dispatch(actionCreators.Actions.userStatsFailed());
        }
    };
