import React, { FunctionComponent } from 'react';
import { t } from 'autobiz-translate';

const Footer: FunctionComponent = () => (
    <>
        <footer>
            <div className="copyright">{t('copy_right')}</div>
        </footer>
    </>
);

export default Footer;
