import React, { FunctionComponent } from 'react';
import { t } from 'autobiz-translate';
import { Button, Container } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { AutobizLogo } from 'autobiz-strap';
import { auth0Credentials } from '../../../../config';
import stlaErrors from './en.json';

const Error: FunctionComponent = () => {
    const { error, logout } = useAuth0();

    let errorObject = {
        provider: '',
        name: '',
        description: '',
    };

    if (error?.message) {
        const splittedError = error.message.split('|');
        if (splittedError.length === 3) {
            const [provider, name, description] = splittedError;
            errorObject = { provider, name, description };
        } else {
            const [message] = splittedError;
            errorObject = { provider: '', name: '', description: message };
        }
    }

    async function customLogout() {
        if (errorObject.provider !== 'AZ') {
            const popup = window.open(
                auth0Credentials.adfsSignoutUrl,
                'logout',
                'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=300,height=300,left=-300,top=-300',
            );

            setTimeout(() => {
                popup?.close();
                logout({
                    logoutParams: {
                        federated: true,
                        returnTo: window.location.origin,
                    },
                });
            }, 3000);
        } else {
            logout({
                logoutParams: {
                    federated: true,
                    returnTo: window.location.origin,
                },
            });
        }
    }

    return (
        <>
            <Container>
                <main>
                    <div
                        className="d-flex flex-column align-items-center justify-content-center  text-center"
                        style={{ height: '100vh' }}
                    >
                        <div className="border px-3 py-4 ">
                            <div className="d-flex align-items-center justify-content-center">
                                <AutobizLogo
                                    product="autobizOffice"
                                    lang="fr"
                                    direction="row"
                                    withBrandNameOnDesktop
                                    withBrandNameOnMobile
                                />
                            </div>
                            <h1 className="h3 mt-4">
                                {errorObject.provider === 'STLA'
                                    ? stlaErrors.authError1
                                    : t('authError1')}
                            </h1>

                            {errorObject.provider === 'AZ' && (
                                <p
                                    className="mt-4"
                                    dangerouslySetInnerHTML={{
                                        __html: t('authErrorAutobiz') || '',
                                    }}
                                />
                            )}
                            {errorObject.provider === 'STLA' && (
                                <p
                                    className="mt-4"
                                    dangerouslySetInnerHTML={{
                                        __html: stlaErrors.authErrorStellantis || '',
                                    }}
                                />
                            )}
                            {errorObject.provider === '' && (
                                <p
                                    className="mt-4"
                                    dangerouslySetInnerHTML={{ __html: t('authError2') || '' }}
                                />
                            )}

                            <p className="error-message">
                                {(errorObject.name === t(errorObject.name) && (
                                    <>{t(errorObject.description)}</>
                                )) || <>{t(errorObject.name)}</>}
                            </p>

                            <Button
                                color="primary"
                                type="button"
                                onClick={async () => {
                                    await customLogout();
                                }}
                            >
                                {errorObject.provider === 'STLA' ? stlaErrors.login : t('login')}
                            </Button>
                        </div>
                    </div>
                </main>
            </Container>
        </>
    );
};

export default Error;
