import { AutobizIcon, AutobizLogo, AutobizSectionTitle } from 'autobiz-strap';
import { t } from 'autobiz-translate';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Tooltip } from 'reactstrap';
import { getUserStatsSelector } from '../../view-models-generators/userStatsSelectors';
import { Loader } from '../Loader/Loader';
import { getUserSelector } from '../../view-models-generators/userSelector';

const UserStats: React.FC = () => {
    const { status, data } = useSelector(getUserStatsSelector);
    const { user } = useSelector(getUserSelector);
    const [tab, setTab] = useState('');

    const [tooltipAverageAppraisalTimePeriodOpen, setTooltipAverageAppraisalTimePeriodOpen] =
        useState(false);
    const [tooltipAverageCostsOpen, setTooltipAverageCostsOpen] = useState(false);
    const [tooltipAveragePricingTimePeriodOpen, setTooltipAveragePricingTimePeriodOpen] =
        useState(false);
    const [tooltipVehicleForSaleOpen, setTooltipVehicleForSaleOpen] = useState(false);
    const [tooltipAverageOpen, setTooltipAverageOpen] = useState(false);

    const toggleAverageAppraisalTimePeriod = () =>
        setTooltipAverageAppraisalTimePeriodOpen(!tooltipAverageAppraisalTimePeriodOpen);
    const toggleAverageCosts = () => setTooltipAverageCostsOpen(!tooltipAverageCostsOpen);

    const toggleAveragePricingTimePeriod = () =>
        setTooltipAveragePricingTimePeriodOpen(!tooltipAveragePricingTimePeriodOpen);
    const toggleVehicleForSale = () => setTooltipVehicleForSaleOpen(!tooltipVehicleForSaleOpen);
    const toggleAverage = () => setTooltipAverageOpen(!tooltipAverageOpen);
    const sourcingExist =
        data.sourcing?.autobizClic2Sell && Object.keys(data.sourcing.autobizClic2Sell).length > 0;
    const inspectionExist =
        data.inspection?.autobizCarcheck && Object.keys(data.inspection.autobizCarcheck).length > 0;
    const pricingExist =
        data.pricing?.autobizCarcheck && Object.keys(data.pricing.autobizCarcheck).length > 0;

    const remarketingExist =
        data.remarketing?.autobizTrade && Object.keys(data.remarketing.autobizTrade).length > 0;
    useEffect(() => {
        if (sourcingExist) setTab('sourcing');
        else if (inspectionExist) setTab('inspection');
        else if (pricingExist) setTab('pricing');
        else if (remarketingExist) setTab('remarketing');
    }, [sourcingExist, inspectionExist, pricingExist, remarketingExist]);

    const d = new Date();
    const currentDay = `0${d.getDate()}`.slice(-2);
    const currentMonth = `0${d.getMonth() + 1}`.slice(-2);

    const displayStaticBloc = sourcingExist || inspectionExist || pricingExist || remarketingExist;

    let dateStart;
    let dateEnd;
    if (user.language === 'en') {
        dateStart = `${currentMonth}/01`;
        dateEnd = `${currentMonth}/${currentDay}`;
    } else {
        dateStart = `01/${currentMonth}`;
        dateEnd = `${currentDay}/${currentMonth}`;
    }

    return (
        <Loader status={status}>
            {displayStaticBloc && (
                <>
                    <div className="mt-4">
                        <AutobizSectionTitle>
                            <AutobizIcon name="StatsChart" /> {t('statistics')}
                        </AutobizSectionTitle>

                        <div className="statsTabs">
                            {data.sourcing && sourcingExist && (
                                <button
                                    type="button"
                                    className={tab === 'sourcing' ? 'active' : ''}
                                    onClick={() => setTab('sourcing')}
                                >
                                    {t('sourcing')}
                                </button>
                            )}
                            {data.inspection && inspectionExist && (
                                <button
                                    type="button"
                                    className={tab === 'inspection' ? 'active' : ''}
                                    onClick={() => setTab('inspection')}
                                >
                                    {t('inspection')}
                                </button>
                            )}
                            {data.pricing && pricingExist && (
                                <button
                                    type="button"
                                    className={tab === 'pricing' ? 'active' : ''}
                                    onClick={() => setTab('pricing')}
                                >
                                    {t('pricing')}
                                </button>
                            )}
                            {data.remarketing && remarketingExist && (
                                <button
                                    type="button"
                                    className={tab === 'remarketing' ? 'active' : ''}
                                    onClick={() => setTab('remarketing')}
                                >
                                    {t('remarketing')}
                                </button>
                            )}
                            <hr />
                        </div>

                        {sourcingExist && (
                            <div
                                className={
                                    data.sourcing && tab === 'sourcing' ? 'd-block' : 'd-none'
                                }
                            >
                                <Row>
                                    <Col xs={12}>
                                        <h5 className="d-inline">{t('sourcing')}</h5>{' '}
                                        <span>
                                            {t('dataFromTo')
                                                ?.replace('[DATE_START]', dateStart)
                                                .replace('[DATE_END]', dateEnd)}
                                        </span>
                                    </Col>
                                    {data.sourcing?.autobizClic2Sell && (
                                        <Col md={6} xl={4}>
                                            <Card className="statsCard">
                                                <CardHeader className="px-3 mx-0">
                                                    <AutobizLogo
                                                        product="autobizClic2Sell"
                                                        withBrandNameOnMobile
                                                        withBrandNameOnDesktop
                                                        direction="row"
                                                        lang="fr"
                                                    />
                                                </CardHeader>

                                                <CardBody>
                                                    <Row>
                                                        {data.sourcing?.autobizClic2Sell
                                                            ?.statisticHBSSMSSentNumber !==
                                                            undefined && (
                                                            <Col xs={6} className="my-2">
                                                                <div className="databox">
                                                                    <div className="font-weight-bold h4 text-autobizClic2Sell">
                                                                        {
                                                                            data.sourcing
                                                                                ?.autobizClic2Sell
                                                                                ?.statisticHBSSMSSentNumber
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {t(
                                                                            'statistic_SMSSentNumber',
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )}

                                                        {data.sourcing?.autobizClic2Sell
                                                            ?.statisticReceivedAppraisalNumber !==
                                                            undefined && (
                                                            <Col xs={6} className="my-2">
                                                                <div className="databox">
                                                                    <div className="font-weight-bold h4 text-autobizClic2Sell">
                                                                        {
                                                                            data.sourcing
                                                                                ?.autobizClic2Sell
                                                                                ?.statisticReceivedAppraisalNumber
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {t(
                                                                            'statistic_ReceivedAppraisalNumber',
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        )}

                        {inspectionExist && (
                            <div
                                className={
                                    data.inspection && tab === 'inspection' ? 'd-block' : 'd-none'
                                }
                            >
                                <Row>
                                    <Col xs={12}>
                                        <h5 className="d-inline">{t('inspection')}</h5>{' '}
                                        <span>
                                            {t('dataFromTo')
                                                ?.replace('[DATE_START]', dateStart)
                                                .replace('[DATE_END]', dateEnd)}
                                        </span>
                                    </Col>
                                    {data.inspection?.autobizCarcheck && (
                                        <Col md={8} lg={6} xl={8}>
                                            <Card className="statsCard">
                                                <CardHeader className="px-3 mx-0">
                                                    <AutobizLogo
                                                        product="autobizCarcheck"
                                                        withBrandNameOnMobile
                                                        withBrandNameOnDesktop
                                                        direction="row"
                                                        lang="fr"
                                                    />
                                                </CardHeader>

                                                <CardBody>
                                                    <Row>
                                                        {data.inspection.autobizCarcheck
                                                            ?.statisticPhysicalCompletedAppraisalNumber !==
                                                            undefined && (
                                                            <Col xs={6} xl={3} className="my-2">
                                                                <div className="databox">
                                                                    <div className="font-weight-bold h4 text-autobizCarcheck">
                                                                        {
                                                                            data.inspection
                                                                                .autobizCarcheck
                                                                                ?.statisticPhysicalCompletedAppraisalNumber
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {t(
                                                                            'physicalCompletedAppraisal',
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )}

                                                        {data.inspection.autobizCarcheck
                                                            ?.statisticHBSCompletedAppraisalNumber !==
                                                            undefined && (
                                                            <Col xs={6} xl={3} className="my-2">
                                                                <div className="databox">
                                                                    <div className="font-weight-bold h4 text-autobizCarcheck">
                                                                        {
                                                                            data.inspection
                                                                                .autobizCarcheck
                                                                                ?.statisticHBSCompletedAppraisalNumber
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {t('hbsCompletedAppraisal')}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )}
                                                        {data.inspection.autobizCarcheck
                                                            ?.statisticAverageAppraisalTimePeriod !==
                                                            undefined && (
                                                            <Col xs={6} xl={3} className="my-2">
                                                                <div className="databox">
                                                                    <div className="font-weight-bold h4 text-autobizCarcheck">
                                                                        {
                                                                            data.inspection
                                                                                .autobizCarcheck
                                                                                ?.statisticAverageAppraisalTimePeriod
                                                                        }{' '}
                                                                        {t('min')}
                                                                    </div>
                                                                    <div>
                                                                        {t(
                                                                            'averageAppraisalTimePeriod',
                                                                        )}{' '}
                                                                        <span
                                                                            id="tooltip_averageAppraisalTimePeriod"
                                                                            style={{
                                                                                verticalAlign:
                                                                                    'bottom',
                                                                            }}
                                                                        >
                                                                            <AutobizIcon
                                                                                name="InfoCircle"
                                                                                size={20}
                                                                            />
                                                                        </span>
                                                                        <Tooltip
                                                                            isOpen={
                                                                                tooltipAverageAppraisalTimePeriodOpen
                                                                            }
                                                                            target="tooltip_averageAppraisalTimePeriod"
                                                                            placement="top"
                                                                            toggle={() =>
                                                                                toggleAverageAppraisalTimePeriod()
                                                                            }
                                                                        >
                                                                            {t(
                                                                                'averageAppraisalTimePeriodInfo',
                                                                            )}
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )}
                                                        {data.inspection.autobizCarcheck
                                                            ?.statisticAverageCosts !==
                                                            undefined && (
                                                            <Col xs={6} xl={3} className="my-2">
                                                                <div className="databox">
                                                                    <div className="font-weight-bold h4 text-autobizCarcheck">
                                                                        {
                                                                            data.inspection
                                                                                .autobizCarcheck
                                                                                ?.statisticAverageCosts
                                                                        }{' '}
                                                                        € <sup>{t('ttc')}</sup>
                                                                    </div>
                                                                    <div>
                                                                        {t('averageCosts')}{' '}
                                                                        <span
                                                                            id="tooltip_averageCosts"
                                                                            style={{
                                                                                verticalAlign:
                                                                                    'bottom',
                                                                            }}
                                                                        >
                                                                            <AutobizIcon
                                                                                name="InfoCircle"
                                                                                size={20}
                                                                            />
                                                                        </span>
                                                                        <Tooltip
                                                                            isOpen={
                                                                                tooltipAverageCostsOpen
                                                                            }
                                                                            target="tooltip_averageCosts"
                                                                            placement="top"
                                                                            toggle={() =>
                                                                                toggleAverageCosts()
                                                                            }
                                                                        >
                                                                            {t('averageCostsInfo')}
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        )}

                        {pricingExist && (
                            <div
                                className={data.pricing && tab === 'pricing' ? 'd-block' : 'd-none'}
                            >
                                <Row>
                                    <Col xs={12}>
                                        <h5 className="d-inline">{t('pricing')}</h5>{' '}
                                        <span>
                                            {t('dataFromTo')
                                                ?.replace('[DATE_START]', dateStart)
                                                .replace('[DATE_END]', dateEnd)}
                                        </span>
                                    </Col>
                                    {data.pricing?.autobizCarcheck && (
                                        <Col md={6} xl={4}>
                                            <Card className="statsCard">
                                                <CardHeader className="px-3 mx-0">
                                                    <AutobizLogo
                                                        product="autobizCarcheck"
                                                        withBrandNameOnMobile
                                                        withBrandNameOnDesktop
                                                        direction="row"
                                                        lang="fr"
                                                    />
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs={6} className="my-2">
                                                            <div className="databox">
                                                                <div className="font-weight-bold h4 text-autobizCarcheck">
                                                                    {
                                                                        data.pricing.autobizCarcheck
                                                                            .statisticAveragePricingTimePeriod
                                                                    }{' '}
                                                                    {t('min')}
                                                                </div>
                                                                <div>
                                                                    {t('averagePricingTimePeriod')}{' '}
                                                                    <span
                                                                        id="tooltip_averagePricingTimePeriod"
                                                                        style={{
                                                                            verticalAlign: 'bottom',
                                                                        }}
                                                                    >
                                                                        <AutobizIcon
                                                                            name="InfoCircle"
                                                                            size={20}
                                                                        />
                                                                    </span>
                                                                    <Tooltip
                                                                        isOpen={
                                                                            tooltipAveragePricingTimePeriodOpen
                                                                        }
                                                                        target="tooltip_averagePricingTimePeriod"
                                                                        placement="top"
                                                                        toggle={() =>
                                                                            toggleAveragePricingTimePeriod()
                                                                        }
                                                                    >
                                                                        {t(
                                                                            'averagePricingTimePeriodInfo',
                                                                        )}
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} className="my-2">
                                                            <div className="databox">
                                                                <div className="font-weight-bold h4 text-autobizCarcheck">
                                                                    {
                                                                        data.pricing.autobizCarcheck
                                                                            ?.statisticPricingRPI
                                                                    }
                                                                </div>
                                                                <div>{t('pricingRpi')}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        )}

                        {remarketingExist && (
                            <div
                                className={
                                    data.remarketing && tab === 'remarketing' ? 'd-block' : 'd-none'
                                }
                            >
                                <Row>
                                    <Col xs={12}>
                                        <h5 className="d-inline">{t('remarketing')}</h5>{' '}
                                        <span>
                                            {t('dataFromTo')
                                                ?.replace('[DATE_START]', dateStart)
                                                .replace('[DATE_END]', dateEnd)}
                                        </span>
                                    </Col>
                                    {data.remarketing?.autobizTrade && (
                                        <Col md={6} xl={4}>
                                            <Card className="statsCard">
                                                <CardHeader className="px-3 mx-0">
                                                    <AutobizLogo
                                                        product="autobizTrade"
                                                        withBrandNameOnMobile
                                                        withBrandNameOnDesktop
                                                        direction="row"
                                                        lang="fr"
                                                    />
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs={6} className="my-2">
                                                            <div className="databox">
                                                                <div className="font-weight-bold h4 text-autobizTrade">
                                                                    {
                                                                        data.remarketing
                                                                            .autobizTrade
                                                                            .statisticVehicleForSaleNumber
                                                                    }{' '}
                                                                </div>
                                                                <div>
                                                                    {t('vehicleForSale')}{' '}
                                                                    <span
                                                                        id="tooltip_vehicleForSale"
                                                                        style={{
                                                                            verticalAlign: 'bottom',
                                                                        }}
                                                                    >
                                                                        <AutobizIcon
                                                                            name="InfoCircle"
                                                                            size={20}
                                                                        />
                                                                    </span>
                                                                    <Tooltip
                                                                        isOpen={
                                                                            tooltipVehicleForSaleOpen
                                                                        }
                                                                        target="tooltip_vehicleForSale"
                                                                        placement="top"
                                                                        toggle={() =>
                                                                            toggleVehicleForSale()
                                                                        }
                                                                    >
                                                                        {t('vehicleForSaleInfo')}
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col xs={6} xl={6} className="my-2">
                                                            <div className="databox">
                                                                <div className="font-weight-bold h4 text-autobizTrade">
                                                                    {
                                                                        data.remarketing
                                                                            .autobizTrade
                                                                            ?.statisticReceivedOfferNumber
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {t('receveidOffer')}{' '}
                                                                    <span
                                                                        id="tooltip_average"
                                                                        style={{
                                                                            verticalAlign: 'bottom',
                                                                        }}
                                                                    >
                                                                        <AutobizIcon
                                                                            name="InfoCircle"
                                                                            size={20}
                                                                        />
                                                                    </span>
                                                                    <Tooltip
                                                                        isOpen={tooltipAverageOpen}
                                                                        target="tooltip_average"
                                                                        placement="top"
                                                                        toggle={() =>
                                                                            toggleAverage()
                                                                        }
                                                                    >
                                                                        {t('receveidOfferInfo')}
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        )}
                    </div>
                    <p
                        className="mt-4"
                        dangerouslySetInnerHTML={{
                            __html: t('statisticExplanation') || '',
                        }}
                    />
                </>
            )}
        </Loader>
    );
};
export default UserStats;
