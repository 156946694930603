import { UserFeeds } from '../../../../../hexagon/models/userFeedsModel';

export const someUserFeeds: UserFeeds = {
    autobizTrade: {
        feed: {
            vehicleForSale: {
                count: 21,
                url: 'https://trade.autobiz.com/fr/records?country=fr&list=all&radius=300&range=0_99&sortLabel=sort_sales_ending_soon_asc&supplyType=STOCK',
            },
            onGoingOfferOnStock: {
                count: 2,
                url: 'https://trade.autobiz.com/fr/records?country=fr&list=my_offers&radius=300&range=0_99&sortLabel=sort_sales_ending_soon_asc&supplyType=STOCK',
            },
        },
        totalFeed: 2,
    },
    autobizCarcheck: {
        feed: {
            OnGoingAppraisal: {
                count: 5,
                url: 'https://app-carcheck.autobiz.com/forms',
            },
            AppraisalToBeSupervised: {
                count: 2,
                url: 'https://app-carcheck.autobiz.com/supervision',
            },
        },
        totalFeed: 7,
    },
    autobizClic2Sell: {
        feed: {
            appraisalCompleted: {
                count: 3,
                url: 'https://admin-hbs.autobiz.com/#/my_form',
            },
        },
        totalFeed: 3,
    },
    totalFeeds: 12,
};
