import { left, right } from 'fp-ts/Either';
import { PostGateway } from '../../../../hexagon/gateways/postGateway.interface';
import { ApiResponse } from '../../../../hexagon/infra/ApiResponse';
import { BaseApi } from '../../../../hexagon/infra/BaseApi';
import { Post } from '../../../../hexagon/models/postModel';
import { WordpressPostDto } from './dtos/wordpressPostDto';
import { PostMapper } from './mappers/post.mapper';

const API_BASE_URL = 'https://office.autobiz.com/wp-json/wp/v2/posts/?categories=72';

export class WordpressPostGateway extends BaseApi implements PostGateway {
    async requestAllPosts(lang: string): Promise<ApiResponse<Post[]>> {
        try {
            const response = await this.get(`${API_BASE_URL}&lang=${lang}`);
            const data = response.data.map((p: WordpressPostDto) => PostMapper.toModel(p));
            return right(data);
        } catch (error) {
            return left({ code: 'postNotFound', message: 'post not found' });
        }
    }
}
