import { Post } from '../../../../../hexagon/models/postModel';

export const somePosts: Post[] = [
    {
        id: '1',
        title: 'Phasellus ultrices ut nostra porttitor',
        excerpt:
            'Augue rhoncus sodales euismod egestas senectus sit amet ridiculus vulputate feugiat. Lobortis commodo lectus ridiculus',
        thumbnail: 'https://picsum.photos/id/237/300/200',
        link: 'https://www.google.fr',
    },
    {
        id: '2',
        title: 'Ligula quam magnis',
        excerpt:
            'Hendrerit sodales consequat cras purus molestie vulputate curabitur! Phasellus ultrices ut nostra porttitor adipiscing magnis magnis tellus ante',
        thumbnail: 'https://picsum.photos/seed/picsum/300/200',
        link: 'https://www.yahoo.fr',
    },
];
