import { left, right } from 'fp-ts/Either';
import { OfficeLanguage } from '../../../../hexagon/config/appConfig';
import { UserDataGateway } from '../../../../hexagon/gateways/userDataGateway.interface';
import { ApiResponse } from '../../../../hexagon/infra/ApiResponse';
import { UserFeeds } from '../../../../hexagon/models/userFeedsModel';
import { UserStats } from '../../../../hexagon/models/userStatsModel';

export class InMemoryUserDataGateway implements UserDataGateway {
    private userFeeds = {} as UserFeeds;

    private userStats = {} as UserStats;

    setToken(token: string): ApiResponse<boolean> {
        // do nothing ...
        if (token) {
            return right(true);
        }

        return left({ code: '401', message: 'not authorized' });
    }

    getUserFeeds(language: OfficeLanguage): Promise<ApiResponse<UserFeeds>> {
        if (this.userFeeds && language) {
            this.userFeeds.totalFeeds = Math.ceil(Math.random() * 100);
            return Promise.resolve(right(this.userFeeds));
        }
        return Promise.resolve(left({ code: 'error_code', message: 'error_message' }));
    }

    getUserStats(): Promise<ApiResponse<UserStats>> {
        if (this.userStats) {
            return Promise.resolve(right(this.userStats));
        }
        return Promise.resolve(left(this.userStats));
    }

    feedUserFeedsWith(userFeeds?: UserFeeds) {
        if (userFeeds) this.userFeeds = userFeeds;
    }

    feedUserStatsWith(userStats?: UserStats) {
        if (userStats) this.userStats = userStats;
    }
}
