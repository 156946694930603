import axios, { AxiosError, AxiosInstance } from 'axios';

export abstract class BaseApi {
    protected baseUrl: string | undefined;

    protected bearerToken: string | undefined;

    protected responseType: string;

    protected headersAccept: string;

    private axiosInstance: AxiosInstance | any = null;

    constructor() {
        this.bearerToken = '';
        this.responseType = 'json';
        this.headersAccept = 'application/json';
        this.axiosInstance = axios.create({});
        this.enableInterceptors();
    }

    private enableInterceptors(): void {
        this.axiosInstance.interceptors.response.use(
            this.getSuccessResponseHandler(),
            (err: AxiosError) => this.getErrorResponseHandler(err),
        );
    }

    private getSuccessResponseHandler() {
        return (response: AxiosError) => response;
    }

    private getErrorResponseHandler(err: AxiosError) {
        if (err.response?.status === 404) {
            throw new Error(`${err.config.url} not found`);
        }
        if (err.response?.status === 403 || err.response?.status === 401) {
            window.location.reload();
        }
        throw err;
    }

    public setResponseType(responseType: string) {
        this.responseType = responseType;
    }

    public setHeadersAccept(headersAccept: string) {
        this.headersAccept = headersAccept;
    }

    public setBearerToken(token: string) {
        this.bearerToken = token;
    }

    private getDefaultHeaders() {
        return {
            Authorization: `Bearer ${this.bearerToken}`,
        };
    }

    protected async get(url: string, props?: any): Promise<any> {
        const { params, headers } = props || { params: null, headers: null };
        return this.axiosInstance({
            method: 'GET',
            url,
            responseType: this.responseType,
            params,
            headers: headers || this.getDefaultHeaders(),
        });
    }

    protected async post(url: string, props?: any): Promise<any> {
        const { headers, data, params }: any = props || {
            headers: null,
            data: null,
            params: null,
        };

        return this.axiosInstance({
            method: 'POST',
            url,
            data,
            params,
            headers: headers || this.getDefaultHeaders(),
        });
    }

    protected async put(url: string, props?: any): Promise<any> {
        const { headers, data, params }: any = props || {
            headers: null,
            data: null,
            params: null,
        };

        return this.axiosInstance({
            method: 'PUT',
            url,
            data,
            params,
            headers: headers || this.getDefaultHeaders(),
        });
    }
}
