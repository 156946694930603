import React, { useEffect, useState } from 'react';
import { t } from 'autobiz-translate';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Cookies from 'js-cookie';

const daysName = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const now = new Date();
const todayDaysName = daysName[now.getDay()];

const broadcastDays = ['tuesday', 'wednesday', 'thursday', 'saturday']; // weekday scope
const broadcastCountry = ['FR']; // country scope
const broadcastProvider = ['AZ']; // provider scope
const broadcastStartDate = new Date('2024-01-30T00:01:00');
const broadcastEndDate = new Date('2024-02-07T00:01:00');

const PopUpWebinar = ({ country, provider }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const hasAlreadySeenPopupToday = () =>
        Cookies.get('popUpWebinarLastSeen') === now.toDateString();

    const userCountry = String(country).toUpperCase();

    const shouldDisplayPopup =
        broadcastDays.includes(todayDaysName) &&
        broadcastCountry.includes(userCountry) &&
        broadcastProvider.includes(provider) &&
        now >= broadcastStartDate &&
        now <= broadcastEndDate &&
        hasAlreadySeenPopupToday() === false;

    const getCurrentDomain = () => {
        if (window.location.port.length > 0) {
            return window.location.hostname;
        }
        return `.${window.location.hostname.match(/\w*\.\w*$/gi)[0]}`;
    };

    useEffect(() => {
        if (shouldDisplayPopup) {
            setModalOpen(true);
            Cookies.set('popUpWebinarLastSeen', now.toDateString(), { domain: getCurrentDomain() });
        }
    }, [userCountry, shouldDisplayPopup]);

    const contentPopUpWebinar = t('contentPopUpWebinar') || '';

    const npmLink = 'https://meet.zoho.eu/flGZo70g1b';

    return (
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
            <ModalHeader className="text-center d-block border-0">
                {t('titlePopUpWebinar')}
            </ModalHeader>
            <ModalBody
                dangerouslySetInnerHTML={{
                    __html: contentPopUpWebinar,
                }}
            />
            <div className="pb-3 px-3">
                <Row>
                    <Col>
                        <Button
                            type="button"
                            color="primary"
                            outline
                            block
                            onClick={() => setModalOpen(!modalOpen)}
                        >
                            {t('closePopUpWebinar')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="button"
                            color="primary"
                            block
                            onClick={() => {
                                window.open(npmLink, '_blank');
                                setModalOpen(!modalOpen);
                            }}
                        >
                            {t('openPopUpWebinar')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default PopUpWebinar;
