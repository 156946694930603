import React, { FunctionComponent, useState } from 'react';
import { t } from 'autobiz-translate';
import { AutobizButton } from 'autobiz-strap';
import { auth0Credentials } from '../../../../config';
import AccessValidationWrapper from './AccessValidationWrapper';

const EmailVerify: FunctionComponent = () => {
    const searchParams = new URLSearchParams(document.location.search);

    const state = searchParams.get('state');
    const onboarding = searchParams.get('onboarding');
    const getEmail = searchParams.get('email');

    const [valid, setValid] = useState(!!getEmail);
    const [email, setEmail] = useState(getEmail || '');

    const { domain } = auth0Credentials;

    const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setEmail(value);
        setValid(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value));
    };

    let wrapperProps = {};

    if (onboarding === 'y') {
        wrapperProps = {
            currentStep: 1,
            title: t('welcome_office'),
            subtitle: t('onboarding_description'),
            label: t('please_validate_your_email'),
        };
    } else {
        wrapperProps = {
            title: t('please_validate_your_email_title'),
            label: t('please_validate_your_email_change'),
        };
    }

    return (
        <AccessValidationWrapper {...wrapperProps}>
            <main>
                <form method="get" action={`https://${domain}/continue`}>
                    <label htmlFor="email">{t('email')}</label>
                    <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={email}
                        onChange={(event) => handleEmailChange(event)}
                    />

                    <input type="hidden" name="state" value={state || ''} />
                    <input type="hidden" name="session" value={state || ''} />
                    <input type="hidden" name="onboarding" value={onboarding || ''} />

                    <AutobizButton type="submit" color="primary" block disabled={!valid}>
                        {t('continue')}
                    </AutobizButton>
                </form>
            </main>
        </AccessValidationWrapper>
    );
};

export default EmailVerify;
