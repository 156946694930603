import { Mapper } from '../../../../../hexagon/infra/Mapper';
import { Preferences } from '../../../../../hexagon/models/preferencesModel';
import { AutobizUserPreferencesDto } from '../dtos/autobizUserPreferencesDto';

const preferencesFormatter = (val: number) =>
    val.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: false,
    });

export class PreferencesMapper implements Mapper<Preferences> {
    static toModel(autobizPreferencesDto: AutobizUserPreferencesDto): Preferences {
        return {
            storageCost: preferencesFormatter(autobizPreferencesDto.storageCost),
            marginEuro: preferencesFormatter(autobizPreferencesDto.marginEuro),
            marginPercentage: preferencesFormatter(autobizPreferencesDto.marginPercentage),
            adminCosts: preferencesFormatter(autobizPreferencesDto.adminCosts),
            reviewCosts: preferencesFormatter(autobizPreferencesDto.reviewCosts),
            warrantyFee1: preferencesFormatter(autobizPreferencesDto.warrantyFee),
            warrantyFee2: preferencesFormatter(autobizPreferencesDto.warrantyFee2),
            warrantyFee3: preferencesFormatter(autobizPreferencesDto.warrantyFee3),
            interestRate: preferencesFormatter(autobizPreferencesDto.interestRate),
            commercialExpenses: preferencesFormatter(autobizPreferencesDto.commercialExpenses),
        };
    }

    static toDto(preferences: Preferences): AutobizUserPreferencesDto {
        return {
            storageCost: Number(preferences.storageCost),
            marginEuro: Number(preferences.marginEuro),
            marginPercentage: Number(preferences.marginPercentage),
            adminCosts: Number(preferences.adminCosts),
            reviewCosts: Number(preferences.reviewCosts),
            warrantyFee: Number(preferences.warrantyFee1),
            warrantyFee2: Number(preferences.warrantyFee2),
            warrantyFee3: Number(preferences.warrantyFee3),
            interestRate: Number(preferences.interestRate),
            commercialExpenses: Number(preferences.commercialExpenses),
        };
    }
}
