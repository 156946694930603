import { configureStore } from './configureStore';
import { InMemoryPostGateway } from '../adapters/secondary/gateways/inMemory/inMemoryPostGateway';
import { somePosts } from '../adapters/secondary/gateways/inMemory/stubs/somePosts';
import { WordpressPostGateway } from '../adapters/secondary/gateways/wordpress/wordpressPostGateway';
import { InMemoryUserDataGateway } from '../adapters/secondary/gateways/inMemory/inMemoryUserDataGateway';
import { someUserFeeds } from '../adapters/secondary/gateways/inMemory/stubs/someUserFeeds';
import { AutobizOfficeApiUserDataGateway } from '../adapters/secondary/gateways/autobizOfficeApi/autobizOfficeApiUserDataGateway';
import { someUserStats } from '../adapters/secondary/gateways/inMemory/stubs/someUserStats';
import { somePreferences } from '../adapters/secondary/gateways/inMemory/stubs/somePreferences';
import {
    // aAutobizUser,
    aStellantisUser,
} from '../adapters/secondary/gateways/inMemory/stubs/someAuthUser';
import { InMemoryUserInfoGateway } from '../adapters/secondary/gateways/inMemory/inMemoryUserInfoGateway';
import { AutobizAccountsUserInfoGateway } from '../adapters/secondary/gateways/AutobizAccountsUserInfoGateway/AutobizAccountsUserInfoGateway';

let postGateway;
let userDataGateway;
let userInfoGateway;

switch (process.env.REACT_APP_SOURCE) {
    case 'inMemory':
        postGateway = new InMemoryPostGateway();
        postGateway.feedWith(somePosts);

        userDataGateway = new InMemoryUserDataGateway();
        userDataGateway.feedUserFeedsWith(someUserFeeds);
        userDataGateway.feedUserStatsWith(someUserStats);
        userInfoGateway = new InMemoryUserInfoGateway();
        userInfoGateway.feedWith(aStellantisUser, somePreferences);

        break;
    default:
    case 'http':
        postGateway = new WordpressPostGateway();
        userDataGateway = new AutobizOfficeApiUserDataGateway();
        userInfoGateway = new AutobizAccountsUserInfoGateway();

        break;
}

const store = configureStore({
    postGateway,
    userInfoGateway,
    userDataGateway,
});

export default store;
