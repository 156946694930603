import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';

const { Actions } = actionCreators;

export const setAccessApplication =
    (accessApplication: string): ThunkResult<void> =>
    async (dispatch) => {
        dispatch(Actions.setAccessApplication(accessApplication));
    };
