import { AnyAction, combineReducers } from 'redux';
import { FetchStatus } from '../appState';
import { Preferences } from '../../hexagon/models/preferencesModel';

export const data = (state: Preferences = {} as Preferences, action: AnyAction) => {
    if (action.type === 'preferences/preferencesReceived') {
        return action.payload.preferences;
    }
    return state;
};

export const status = (state: FetchStatus = 'idle', action: AnyAction) => {
    if (action.type === 'preferences/preferencesRequested') return 'loading';
    if (action.type === 'preferences/preferencesReceived') return 'succeeded';
    if (action.type === 'preferences/preferencesRequestFailed') return 'failed';

    return state;
};

export const preferencesReducer = combineReducers({
    status,
    data,
});
