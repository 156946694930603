import React, { FunctionComponent, useEffect, useState } from 'react';
import { t } from 'autobiz-translate';
import { AutobizButton } from 'autobiz-strap';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js/types';
import { auth0Credentials } from '../../../../config';
import AccessValidationWrapper from './AccessValidationWrapper';

const PhoneVerify: FunctionComponent = () => {
    const searchParams = new URLSearchParams(document.location.search);

    const state = searchParams.get('state');
    const onboarding = searchParams.get('onboarding');
    const getPhone = searchParams.get('phone');
    const [valid, setValid] = useState<boolean>(false);
    const [phone, setPhone] = useState<string | undefined>(getPhone || '');

    const { domain } = auth0Credentials;

    useEffect(() => {
        if (phone) {
            setValid(isPossiblePhoneNumber(phone));
        } else {
            setValid(false);
        }
    }, [phone]);

    const defaultPhonePrefix: CountryCode = 'FR';
    const availablePhonePrefix = [
        'FR',
        'ES',
        'IT',
        'PT',
        'GB',
        'DE',
        'BE',
        'LU',
        'AT',
        'NL',
    ] as CountryCode[];

    const phonePrefixCountryLabel = {
        FR: t('france'),
        ES: t('spain'),
        IT: t('italy'),
        PT: t('portugal'),
        GB: t('greatBritain'),
        DE: t('germany'),
        BE: t('belgium'),
        LU: t('luxembourg'),
        AT: t('austria'),
        NL: t('netherlands'),
    };

    let wrapperProps = {};

    if (onboarding === 'y') {
        wrapperProps = {
            currentStep: 2,
            title: t('welcome_office'),
            label: t('please_validate_your_phone'),
        };
    } else {
        wrapperProps = {
            title: t('please_validate_your_phone_title'),
            label: t('please_validate_your_phone_change'),
        };
    }

    return (
        <AccessValidationWrapper {...wrapperProps}>
            <main>
                <form method="get" action={`https://${domain}/continue`}>
                    <label htmlFor="phone">{t('phone_number')}</label>

                    <PhoneInput
                        international={false}
                        defaultCountry={defaultPhonePrefix}
                        labels={phonePrefixCountryLabel}
                        countries={availablePhonePrefix}
                        value={phone}
                        onChange={setPhone}
                    />

                    <input type="hidden" name="phone" value={phone?.replace('+', '%2B') || ''} />

                    <input type="hidden" name="state" value={state || ''} />
                    <input type="hidden" name="session" value={state || ''} />
                    <input type="hidden" name="onboarding" value={onboarding || ''} />

                    <AutobizButton type="submit" color="primary" block disabled={!valid}>
                        {t('continue')}
                    </AutobizButton>
                </form>
            </main>
        </AccessValidationWrapper>
    );
};

export default PhoneVerify;
