import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';
import { UserInfoGateway } from '../../gateways/userInfoGateway.interface';

export const getUserInfoUseCase =
    (): ThunkResult<void> =>
    async (dispatch, getState, { userInfoGateway }: { userInfoGateway: UserInfoGateway }) => {
        dispatch(actionCreators.Actions.userInfoFetching());
        try {
            const user = await userInfoGateway.getCurrentUserInfo();
            if (isRight(user)) {
                dispatch(actionCreators.Actions.userInfoRetrieved(user.right));
            } else {
                dispatch(actionCreators.Actions.userInfoFailed());
            }
        } catch (err) {
            dispatch(actionCreators.Actions.userInfoFailed());
        }
    };
