import { Preferences } from '../../../hexagon/models/preferencesModel';
import { AppState, FetchStatus } from '../../../redux/appState';

export interface PreferencesVM {
    status: FetchStatus;
    data: Preferences;
}

export const getPreferencesSelector = (state: AppState): PreferencesVM => ({
    status: state.preferences.status,
    data: state.preferences.data,
});
