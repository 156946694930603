import { ActionsUnion, createAction } from '../../../redux/customActions';
import { Preferences } from '../../models/preferencesModel';

export const Actions = {
    userPreferencesFetching: () => createAction('preferences/preferencesRequested'),
    userPreferencesFailed: () => createAction('preferences/preferencesRequestFailed'),
    userPreferencesRetrieved: (preferences: Preferences) =>
        createAction('preferences/preferencesReceived', { preferences }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
