import React from 'react';
import { Button, Container } from 'reactstrap';
import { t } from 'autobiz-translate';
import { useNavigate } from 'react-router';
import { AutobizNavBar } from 'autobiz-strap';

const AccessDenied: React.FC = () => {
    const navigate = useNavigate();

    const handleRedirectToDashaboard = () => {
        navigate('/dashboard');
    };

    return (
        <>
            <AutobizNavBar
                brandLink="./dashboard"
                lang="fr"
                maxWidth={1140}
                brand="autobizOffice"
            />
            <Container className="hasFixedHeader">
                <main>
                    <div
                        className="d-flex flex-column align-items-center justify-content-center  text-center"
                        style={{ height: '50vh' }}
                    >
                        <div className=" px-3 py-4 ">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: t('messageDeniedAccess') || '',
                                }}
                            />

                            <Button
                                color="primary"
                                type="button"
                                onClick={async () => {
                                    handleRedirectToDashaboard();
                                }}
                            >
                                {t('ctaDashboard')}
                            </Button>
                        </div>
                    </div>
                </main>
            </Container>
        </>
    );
};
export default AccessDenied;
