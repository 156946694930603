const ENV = process.env.REACT_APP_ENV;

const API_USERS = {
    local: 'https://stg-api.shakazoola.com/users/v1',
    dev: 'https://stg-api.shakazoola.com/users/v1',
    stg: 'https://stg-api.shakazoola.com/users/v1',
    ppr: 'https://ppr-api.shakazoola.com/users/v1',
    prod: 'https://apiv2.autobiz.com/users/v1',
};
export const API_USER_URL = API_USERS[ENV];

const API_OFFICE = {
    local: 'https://stg-office-api.shakazoola.com',
    dev: 'https://stg-office-api.shakazoola.com',
    stg: 'https://stg-office-api.shakazoola.com',
    ppr: 'https://ppr-office-api.autobiz.com',
    prod: 'https://office-api.autobiz.com',
};

export const API_OFFICE_URL = API_OFFICE[ENV];

const API_CARCHECK = {
    local: 'https://stg-api-carcheck.shakazoola.com/v1',
    dev: 'https://stg-api-carcheck.shakazoola.com/v1',
    stg: 'https://stg-api-carcheck.shakazoola.com/v1',
    ppr: 'https://ppr-api-carcheck.shakazoola.com/v1',
    prod: 'https://api-carcheck.autobiz.com/v1',
};

export const API_CARCHECK_URL = API_CARCHECK[ENV];

const API_TRADE = {
    local: 'https://dev-api-trade.shakazoola.com',
    dev: 'https://dev-api-trade.shakazoola.com',
    stg: 'https://stg72-api-trade.shakazoola.com',
    ppr: 'https://ppr-api-trade.autobiz.com',
    prod: 'https://api-trade.autobiz.com',
};

export const API_TRADE_URL = API_TRADE[ENV];

export const linkList = {
    contactHref: '/contact/',
    confidentialite: '/vie-privee-et-cookies/',
    cgu: '/cgu/',
    accessibilite: '/mentions-legales/',
};

const officeUrl = {
    local: 'https://stg-office-connect.shakazoola.com',
    dev: 'https://dev-office-connect.shakazoola.com',
    stg: 'https://stg-office-connect.shakazoola.com',
    ppr: 'https://ppr-office-connect.autobiz.com',
    prod: 'https://office-connect.autobiz.com',
};

export const OFFICE_URL_BASE = officeUrl[ENV];

const accountsUrl = {
    local: 'https://stg-accounts.shakazoola.com/api',
    dev: 'https://stg-accounts.shakazoola.com/api',
    stg: 'https://stg-accounts.shakazoola.com/api',
    ppr: 'https://ppr-accounts.shakazoola.com/api',
    prod: 'https://accounts.autobiz.com/api',
};

export const ACCOUNTS_URL_BASE = accountsUrl[ENV];

const domains = {
    local: 'localhost',
    dev: '.shakazoola.com',
    stg: '.shakazoola.com',
    ppr: '.shakazoola.com',
    prod: '.autobiz.com',
};

const auth0CredentialURL = {
    local: {
        domain: 'stg-login-office.shakazoola.com',
        clientId: 'Pm2kAJi2S6FdmxQX9meDQrSXMzqgnr5o',
        redirectUri: 'http://localhost:3000/dashboard',
        adfsSignoutUrl: 'https://sts-obsdev.fiat.com/adfs/ls?wa=wsignout1.0',
    },
    dev: {
        domain: 'stg-login-office.shakazoola.com',
        clientId: 'Pm2kAJi2S6FdmxQX9meDQrSXMzqgnr5o',
        redirectUri: 'https://dev-office-connect.shakazoola/dashboard',
        adfsSignoutUrl: 'https://sts-obsdev.fiat.com/adfs/ls?wa=wsignout1.0',
    },
    stg: {
        domain: 'stg-login-office.shakazoola.com',
        clientId: 'Pm2kAJi2S6FdmxQX9meDQrSXMzqgnr5o',
        redirectUri: 'https://stg-office-connect.shakazoola.com/dashboard',
        adfsSignoutUrl: 'https://sts-obsdev.fiat.com/adfs/ls?wa=wsignout1.0',
    },
    ppr: {
        domain: 'ppr-login-office.shakazoola.com',
        clientId: 'Rpd5SZ7TnmFuBZrMNdJnQdzNpU2DnLgO',
        redirectUri: 'https://ppr-office-connect.autobiz.com/dashboard',
        adfsSignoutUrl: 'https://sts-obsdev.fiat.com/adfs/ls?wa=wsignout1.0',
    },
    prod: {
        domain: 'login-office.autobiz.com',
        clientId: 'hdSSk4ruVhGgcZ30LYzccgskY9HRGfFH',
        redirectUri: 'https://office-connect.autobiz.com/dashboard',
        adfsSignoutUrl: 'https://sts.fiatgroup.com/adfs/ls?wa=wsignout1.0',
    },
};

export const auth0Credentials = auth0CredentialURL[ENV];

export const DOMAIN = domains[ENV];

export const BASE_OFFICE = 'https://office.autobiz.com';
