import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import '../../../app.scss';
import { useDispatch } from 'react-redux';
import { AutobizTheme, defaultTheme } from 'autobiz-strap';

import Dashboard from './Dashboard/Dashboard';
import Preferences from './Preferences/Preferences';
import { setAccessApplication } from '../../../hexagon/usecases/setAccessApplication/setAccessApplication.Usecase';

import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import AcceptCgu from './AccessValidation/AcceptCgu';
import UserProfile from './UserProfile/UserProfile';
import EmailVerify from './AccessValidation/EmailVerify';
import EmailVerifyCode from './AccessValidation/EmailVerifyCode';
import PhoneVerify from './AccessValidation/PhoneVerify';
import PhoneVerifyCode from './AccessValidation/PhoneVerifyCode';
import AcceptCgv from './AccessValidation/AcceptCgv';
import AccessDenied from './AccessDenied/AccessDenied';

const App = () => {
    const dispatch = useDispatch();

    const queryParameters = new URLSearchParams(window.location.search);
    const accessApplication = queryParameters.get('accessApplication');
    if (accessApplication && accessApplication.length > 0)
        dispatch(setAccessApplication(accessApplication));
    return (
        <>
            <AutobizTheme theme={defaultTheme}>
                <BrowserRouter>
                    <Auth0ProviderWithNavigate>
                        <Routes>
                            {/* preferences */}
                            <Route path="/preferences" element={<Preferences />} />
                            <Route
                                path="/:language/preferences"
                                element={<Navigate replace to="/preferences" />}
                            />
                            {/* dashboard */}
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route
                                path="/:language/dashboard"
                                element={<Navigate replace to="/dashboard" />}
                            />

                            {/* access-validation/email */}
                            <Route path="/access-validation/email" element={<EmailVerify />} />
                            <Route
                                path="/:language/access-validation/email"
                                element={
                                    <Navigate
                                        replace
                                        to={`/access-validation/email${window.location.search}`}
                                    />
                                }
                            />

                            {/* access-validation/email-verify-code */}
                            <Route
                                path="/access-validation/email-verify-code"
                                element={<EmailVerifyCode />}
                            />
                            <Route
                                path="/:language/access-validation/email-verify-code"
                                element={
                                    <Navigate
                                        replace
                                        to={`/access-validation/email-verify-code${window.location.search}`}
                                    />
                                }
                            />

                            {/* access-validation/phone */}
                            <Route path="/access-validation/phone" element={<PhoneVerify />} />
                            <Route
                                path="/:language/access-validation/phone"
                                element={
                                    <Navigate
                                        replace
                                        to={`/access-validation/phone${window.location.search}`}
                                    />
                                }
                            />

                            {/* access-validation/phone-verify-code */}
                            <Route
                                path="/access-validation/phone-verify-code"
                                element={<PhoneVerifyCode />}
                            />
                            <Route
                                path="/:language/access-validation/phone-verify-code"
                                element={
                                    <Navigate
                                        replace
                                        to={`/access-validation/phone-verify-code${window.location.search}`}
                                    />
                                }
                            />

                            {/* access-validation/cgu */}
                            <Route path="/access-validation/cgu" element={<AcceptCgu />} />
                            <Route
                                path="/:language/access-validation/cgu"
                                element={
                                    <Navigate
                                        replace
                                        to={`/access-validation/cgu${window.location.search}`}
                                    />
                                }
                            />

                            {/* access-validation/cgv */}
                            <Route path="/access-validation/cgv" element={<AcceptCgv />} />
                            <Route
                                path="/:language/access-validation/cgv"
                                element={
                                    <Navigate
                                        replace
                                        to={`/access-validation/cgv${window.location.search}`}
                                    />
                                }
                            />

                            {/* access-validation/userprofile */}
                            <Route path="/userprofile" element={<UserProfile />} />
                            <Route
                                path="/:language/access-validation/userprofile"
                                element={
                                    <Navigate
                                        replace
                                        to={`/access-validation/userprofile${window.location.search}`}
                                    />
                                }
                            />

                            {/* access-denied */}
                            <Route path="/access-denied" element={<AccessDenied />} />

                            <Route path="/*" element={<Navigate replace to="/dashboard" />} />
                        </Routes>
                    </Auth0ProviderWithNavigate>
                </BrowserRouter>
            </AutobizTheme>
        </>
    );
};

export default App;
