import jwtDecode from 'jwt-decode';
import { Mapper } from '../../../../../hexagon/infra/Mapper';
import { User, UserLanguage } from '../../../../../hexagon/models/userModel';
import { AutobizAccountsUserInfoDto, IdTokenDto } from '../dtos/autobizAccountsUserInfoDto';
import { appDefaultLang, officeLanguages } from '../../../../../hexagon/config/appConfig';

export class UserInfoMapper implements Mapper<User> {
    static toModel(dto: AutobizAccountsUserInfoDto, idToken: string): User {
        const idTokenValues = jwtDecode(idToken || '') as IdTokenDto;

        return {
            id: dto.id,
            firstName: dto.firstname,
            lastName: dto.lastname,
            country: dto.tags.COUNTRY,
            email: dto.email,
            phone: dto.phone_number,
            language: (officeLanguages
                .map((language) => language.language_iso2)
                .includes(dto.preferred_language)
                ? dto.preferred_language
                : appDefaultLang) as UserLanguage,
            subscriptionId: dto.tags.SUBSCRIPTION,
            canEditPreferences: false,
            provider: dto.provider,
            userApps: idTokenValues.office_apps || [],
        };
    }
}
