import { Action, applyMiddleware, combineReducers, createStore, Store } from 'redux';
import thunk, { ThunkAction, ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { AppState } from './appState';
import { Dependencies } from './dependencies.interface';
import { postsReducer } from './reducers/postsReducer';
import { userFeedsReducer } from './reducers/userFeedsReducer';
import { preferencesReducer } from './reducers/preferencesReducer';
import { userReducer } from './reducers/userReducer';
import { userStatsReducer } from './reducers/userStatsReducer';
import { clientReducer } from './reducers/clientReducer';

export const configureStore = (dependencies?: Partial<Dependencies>) =>
    createStore(
        combineReducers({
            posts: postsReducer,
            auth: userReducer,
            userFeeds: userFeedsReducer,
            userStats: userStatsReducer,
            preferences: preferencesReducer,
            client: clientReducer,
        }),
        composeWithDevTools(
            applyMiddleware(
                dependencies
                    ? (thunk.withExtraArgument(dependencies) as ThunkMiddleware<
                          AppState,
                          Action,
                          any
                      >)
                    : thunk,
            ),
        ),
    );

export type ReduxStore = Store<AppState> & {
    dispatch: ThunkDispatch<AppState, any, Action>;
};

export type ThunkResult<R> = ThunkAction<R, AppState, any, Action>;
