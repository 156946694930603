import React from 'react';
import { Helmet } from 'react-helmet';

const ZohoSurvey = ({ user }) => {
    const broadcastProvider = ['AZ']; // provider scope
    const broadcastCountry = ['FR', 'ES', 'IT', 'PT']; // country scope
    const endDate = new Date('2024-06-28'); // end date

    const shouldDisplayPopup =
        user.email !== undefined &&
        broadcastProvider.includes(user.provider) &&
        broadcastCountry.includes(user.country) &&
        new Date() < endDate;

    let zohoSurveyCode = '';

    switch (user.country) {
        default:
        case 'FR':
            zohoSurveyCode = 'r6T2OF';
            break;
        case 'ES':
            zohoSurveyCode = 'ryTIi6';
            break;
        case 'PT':
            zohoSurveyCode = 'pyT4bU';
            break;
        case 'IT':
            zohoSurveyCode = '3HTYcJ';
            break;
    }

    if (!shouldDisplayPopup) return <></>;
    return (
        <Helmet>
            <style type="text/css">{`
            iframe#zsitc_frame{
                display:block;
            }
            .sCWrap {
                width: 80vw !important;
                max-width: 760px !important;
            }

            .sCWrap .popOutiframe {
                height: 80vh;
            }
         `}</style>

            <script type="text/javascript">
                {`
(function(w, d, s, u, f, m, n, o) {
o = "https://survey.zohopublic.eu";
w[f] =
  w[f] ||
  function() {
    (w[f].p = w[f].p || []).push(arguments);
  };
(m = d.createElement(s)), (n = d.getElementsByTagName(s)[0]);
m.async = 1;
m.src = o + u;
n.parentNode.insertBefore(m, n);
zs_intercept(o, "${zohoSurveyCode}?az_mail=${user.email}", {
  minHeight: 350,
  heightType: 1,
  maxHeight: 700,
  minimizeView: 1,
  position: 2,
  hideEndPage: true,
});
})(
window,
document,
"script",
"/api/v1/public/livesurveys/wOBuVi/popup/script",
"zs_intercept"
);
`}
            </script>
        </Helmet>
    );
};
export default ZohoSurvey;
