import { AnyAction, combineReducers } from 'redux';
import { FetchStatus } from '../appState';
import { UserStats } from '../../hexagon/models/userStatsModel';

export const data = (state: UserStats = {} as UserStats, action: AnyAction) => {
    if (action.type === 'userStats/userStatsReceived') {
        return action.payload.userStats;
    }
    return state;
};

export const status = (state: FetchStatus = 'idle', action: AnyAction) => {
    if (action.type === 'userStats/userStatsRequested') return 'loading';
    if (action.type === 'userStats/userStatsReceived') return 'succeeded';
    if (action.type === 'userStats/userStatsRequestFailed') return 'failed';

    return state;
};

export const userStatsReducer = combineReducers({
    status,
    data,
});
