import { ProductName } from '../models/productModel';

export const allProducts: ProductName[] = [
    'autobizOffice',
    'autobizCarcheck',
    'autobizMarket',
    'autobizTrade',
    'autobizMonStock',
    'autobizIframe',
    'autobizClic2Buy',
    'autobizAdsScan',
    'autobizClic2Sell',
    'autobizAPI',
    'autobizAcademy',
];

export const newProducts: ProductName[] = ['autobizMarket_v2'];

export type OfficeLanguage = 'fr' | 'es' | 'it' | 'pt' | 'en' | 'nl' | 'de';

export const appDefaultLang = 'en';

export const officeLanguages = [
    { id: 'b0111406-44b7-4dd8-be81-43914ec2b62e', language_iso2: 'fr', flag: '🇫🇷' },
    { id: 'd1cc1d31-ce08-4f19-8efc-5ca65880c43b', language_iso2: 'es', flag: '🇪🇦' },
    { id: '7104cb7f-9491-410a-9d36-2ee75d38ff2d', language_iso2: 'it', flag: '🇮🇹' },
    { id: 'a6c0190d-88b3-42a3-8d2d-b6bd5520eedc', language_iso2: 'pt', flag: '🇵🇹' },
    { id: 'beb9ef98-9a20-4a56-9abb-8e9c2979ba19', language_iso2: 'en', flag: '🇬🇧' },
    { id: '72d280d4-bf12-4a51-aa2e-796c90563426', language_iso2: 'nl', flag: '🇳🇱' },
    { id: '2f5de7f7-b67c-4309-a34e-33e07f6237e8', language_iso2: 'de', falg: '🇩🇪 ' },
];

export type OfficeCountry =
    | 'fr'
    | 'es'
    | 'it'
    | 'pt'
    | 'uk'
    | 'nl'
    | 'de'
    | 'at'
    | 'cz'
    | 'ch'
    | 'ro'
    | 'se'
    | 'dk'
    | 'pl'
    | 'gr'
    | 'ie'
    | 'hu'
    | 'fi'
    | 'sk'
    | 'no'
    | 'be'
    | 'lu';

export const userFeedsRefreshDelayInSeconds = 180 * 1000;

export const defaultSubscriptionPreferences = 44;
