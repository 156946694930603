import React, { FunctionComponent, useState } from 'react';
import { t } from 'autobiz-translate';
import { AutobizButton } from 'autobiz-strap';
import { Alert } from 'reactstrap';
import { useNavigate } from 'react-router';
import { auth0Credentials } from '../../../../config';
import AccessValidationWrapper from './AccessValidationWrapper';

const PhoneVerifyCode: FunctionComponent = () => {
    const [valid, setValid] = useState(false);
    const [code, setCode] = useState('');
    const [touched, setTouched] = useState(false);

    const searchParams = new URLSearchParams(document.location.search);

    const state = searchParams.get('state');
    const session = searchParams.get('session');
    const verifyPhone = searchParams.get('verifyPhone');
    const errMessage = searchParams.get('errMessage');
    const onboarding = searchParams.get('onboarding');

    const navigate = useNavigate();

    const { domain } = auth0Credentials;

    const handleCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
        setTouched(true);
        const { value } = event.currentTarget;
        setCode(value.toUpperCase());
        setValid(value.length === 6);
    };

    let wrapperProps = {};

    if (onboarding === 'y') {
        wrapperProps = {
            currentStep: 2,
            title: t('welcome_office'),
            label: `${t('enter_phone_verification_code')} ${verifyPhone}`,
        };
    } else {
        wrapperProps = {
            title: t('please_validate_your_phone_title'),
            label: `${t('enter_phone_verification_code')} ${verifyPhone}`,
        };
    }

    return (
        <AccessValidationWrapper {...wrapperProps}>
            <main>
                {errMessage && touched === false && (
                    <Alert color="danger" className="text-center">
                        {t(errMessage)}
                    </Alert>
                )}

                <form method="get" action={`https://${domain}/continue`}>
                    <label htmlFor="phone">{t('set_code')}</label>
                    <input
                        type="text"
                        name="verifyCode"
                        className="form-control"
                        value={code}
                        onChange={handleCodeChange}
                        style={{
                            fontSize: '30px',
                            textAlign: 'center',
                            margin: 'auto',
                        }}
                        maxLength={6}
                    />

                    <a href="#" className="small" onClick={() => navigate('/dashboard')}>
                        {t('modify_my_phone')}
                    </a>

                    <input type="hidden" name="state" value={state || ''} />

                    <input
                        type="hidden"
                        name="verifyPhone"
                        value={verifyPhone?.replace('+', '%2B') || ''}
                    />
                    <input type="hidden" name="session" value={session || ''} />

                    <AutobizButton type="submit" block color="primary" disabled={!valid}>
                        {t('continue')}
                    </AutobizButton>
                </form>
            </main>
        </AccessValidationWrapper>
    );
};

export default PhoneVerifyCode;
