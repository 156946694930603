import React from 'react';
import { Helmet } from 'react-helmet';

interface HelmetComponentProps {
    language: string;
}

const HelmetComponent: React.FC<HelmetComponentProps> = ({ language }) => {
    const titles: { [key: string]: string } = {
        fr: 'autobizOffice | Optimisez votre activité automobile VO de A à Z',
        en: 'autobizOffice | Optimise your UC activity from A to Z',
        es: 'autobizOffice | Optimiza tu negocio VO de la A a la Z',
        it: 'autobizOffice | Ottimizza il tuo business VO dalla A alla Z',
        de: 'autobizOffice | Optimieren Sie Ihr GW-Geschäft von A bis Z',
        pt: 'autobizOffice | Optimize seu negocio em VO de A a Z',
        nl: 'autobizOffice | Optimaliseer uw autobedrijf van A tot Z',
    };

    const urls: { [key: string]: string } = {
        fr: 'https://office-connect.autobiz.com/',
        es: 'https://office-connect.autobiz.com/',
        it: 'https://office-connect.autobiz.com/',
        en: 'https://office-connect.autobiz.com/',
        de: 'https://office-connect.autobiz.com/',
        pt: 'https://office-connect.autobiz.com/',
        nl: 'https://office-connect.autobiz.com/',
    };

    return (
        <Helmet
            htmlAttributes={{ lang: language }}
            link={[
                {
                    rel: 'canonical',
                    href: urls[language],
                },
                {
                    rel: 'alternate',
                    href: urls.fr,
                    hrefLang: 'fr-FR',
                },
                {
                    rel: 'alternate',
                    href: urls.es,
                    hrefLang: 'es-ES',
                },
                {
                    rel: 'alternate',
                    href: urls.it,
                    hrefLang: 'it-IT',
                },
                {
                    rel: 'alternate',
                    href: urls.pt,
                    hrefLang: 'pt-PT',
                },
                {
                    rel: 'alternate',
                    href: urls.en,
                    hrefLang: 'en-EN',
                },
                {
                    rel: 'alternate',
                    href: urls.de,
                    hrefLang: 'de-DE',
                },
                {
                    rel: 'alternate',
                    href: urls.nl,
                    hrefLang: 'nl-NL',
                },
            ]}
            title={titles[language]}
        />
    );
};

export default HelmetComponent;
