import React, { FunctionComponent, useState } from 'react';
import { t } from 'autobiz-translate';
import { AutobizButton } from 'autobiz-strap';
import { FormGroup, Label, Input } from 'reactstrap';
import { auth0Credentials } from '../../../../config';
import AccessValidationWrapper from './AccessValidationWrapper';

const AcceptCgu: FunctionComponent = () => {
    const [accepted, setAccepted] = useState(false);

    const searchParams = new URLSearchParams(document.location.search);

    const state = searchParams.get('state');

    const { domain } = auth0Credentials;

    const handleClick = () => {
        const continueUrl = `https://${domain}/continue?state=${state}`;
        window.location.replace(continueUrl);
    };

    let wrapperProps = {};

    wrapperProps = {
        currentStep: 3,
        title: t('welcome_office'),
        label: t('accept_terms_of_service_description'),
    };

    const termsOfService = t('accept_terms_of_service_input') || '';

    return (
        <AccessValidationWrapper {...wrapperProps}>
            <main>
                <FormGroup className="mb-5">
                    <Label>
                        <Input
                            type="checkbox"
                            name="checkbox"
                            onClick={() => setAccepted(!accepted)}
                            style={{
                                width: 20,
                                height: 20,
                                marginLeft: '-1.75rem',
                                marginTop: 0,
                            }}
                        />{' '}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: termsOfService,
                            }}
                        />
                    </Label>
                </FormGroup>

                <AutobizButton
                    type="button"
                    onClick={handleClick}
                    color="primary"
                    block
                    disabled={!accepted}
                >
                    {t('accept_terms_of_service_continue')}
                </AutobizButton>
            </main>
        </AccessValidationWrapper>
    );
};

export default AcceptCgu;
