import { AnyAction, combineReducers } from 'redux';
import { FetchStatus } from '../appState';
import { UserFeeds } from '../../hexagon/models/userFeedsModel';

export const data = (state: UserFeeds = {} as UserFeeds, action: AnyAction) => {
    if (action.type === 'userFeeds/userFeedsReceived') {
        return action.payload.userFeeds;
    }
    return state;
};

export const status = (state: FetchStatus = 'idle', action: AnyAction) => {
    if (action.type === 'userFeeds/userFeedsRequested') return 'loading';
    if (action.type === 'userFeeds/userFeedsReceived') return 'succeeded';
    if (action.type === 'userFeeds/userFeedsRequestFailed') return 'failed';

    return state;
};

export const userFeedsReducer = combineReducers({
    status,
    data,
});
