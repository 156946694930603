import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import store from '../../../redux/startupScript';
import * as actionCreators from './actionCreators';
import { UserInfoGateway } from '../../gateways/userInfoGateway.interface';

export const getUserPreferencesUseCase =
    (): ThunkResult<void> =>
    async (dispatch, getState, { userInfoGateway }: { userInfoGateway: UserInfoGateway }) => {
        const { subscriptionId, country } = store.getState().auth.user;

        dispatch(actionCreators.Actions.userPreferencesFetching());
        const result = await userInfoGateway.getPreferences(subscriptionId, country);

        if (isRight(result)) {
            dispatch(actionCreators.Actions.userPreferencesRetrieved(result.right));
        } else {
            dispatch(actionCreators.Actions.userPreferencesFailed());
        }
    };

export const getUserDefaultPreferencesUseCase =
    (): ThunkResult<void> =>
    async (dispatch, getState, { userInfoGateway }: { userInfoGateway: UserInfoGateway }) => {
        const { country } = store.getState().auth.user;

        dispatch(actionCreators.Actions.userPreferencesFetching());
        const result = await userInfoGateway.getDefaultPreferences(country);
        if (isRight(result)) {
            dispatch(actionCreators.Actions.userPreferencesRetrieved(result.right));
        } else {
            dispatch(actionCreators.Actions.userPreferencesFailed());
        }
    };
