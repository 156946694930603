import { AnyAction, combineReducers } from 'redux';
import { FetchStatus } from '../appState';
import { Post } from '../../hexagon/models/postModel';

export const data = (state: Post[] = [], action: AnyAction) => {
    if (action.type === 'posts/postsReceived') {
        return action.payload.posts;
    }
    return state;
};

export const status = (state: FetchStatus = 'idle', action: AnyAction) => {
    if (action.type === 'posts/postsRequested') return 'loading';
    if (action.type === 'posts/postsReceived') return 'succeeded';
    if (action.type === 'posts/postsRequestFailed') return 'failed';

    return state;
};

export const postsReducer = combineReducers({
    status,
    data,
});
