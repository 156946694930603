import { AnyAction, combineReducers } from 'redux';

export const accessApplication = (state = '', action: AnyAction) => {
    if (action.type === 'client/setAccessApplication') {
        return action.payload;
    }

    return state;
};

export const clientReducer = combineReducers({
    accessApplication,
});
