import { ActionsUnion, createAction } from '../../../redux/customActions';
import { User } from '../../models/userModel';

export const Actions = {
    userInfoFetching: () => createAction('userInfo/userInfoRequested'),
    userInfoFailed: () => createAction('userInfo/userInfoRequestFailed'),
    userInfoRetrieved: (user: User) => createAction('userInfo/userInfoReceived', { user }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
