import { Preferences } from '../../../../../hexagon/models/preferencesModel';

export const somePreferences: Preferences = {
    storageCost: '100.00',
    marginEuro: '400.00',
    marginPercentage: '500.00',
    adminCosts: '180.00',
    reviewCosts: '600.00',
    warrantyFee1: '100.00',
    warrantyFee2: '200.00',
    warrantyFee3: '300.00',
    interestRate: '30.00',
    commercialExpenses: '22.00',
};
