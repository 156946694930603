import { left, right } from 'fp-ts/Either';
import { ApiResponse } from '../../../../hexagon/infra/ApiResponse';
import { UserInfoGateway } from '../../../../hexagon/gateways/userInfoGateway.interface';
import { UpdatableUserInfo, User } from '../../../../hexagon/models/userModel';
import { Preferences } from '../../../../hexagon/models/preferencesModel';
import { OfficeLanguage, officeLanguages } from '../../../../hexagon/config/appConfig';

export class InMemoryUserInfoGateway implements UserInfoGateway {
    private user = {} as User;

    private preferences = {} as Preferences;

    setToken(token: string): ApiResponse<boolean> {
        // do nothing ...
        if (this.user && token) {
            return right(true);
        }

        return left({ code: '401', message: 'not authorized' });
    }

    getCurrentUserInfo(): Promise<ApiResponse<User>> {
        try {
            return Promise.resolve(right(this.user));
        } catch {
            return Promise.resolve(left({ code: 'error_code', message: 'error_message' }));
        }
    }

    updateUserInfo(userInfo: Partial<UpdatableUserInfo>): Promise<ApiResponse<boolean>> {
        if (userInfo) {
            try {
                return Promise.resolve(right(true));
            } catch {
                return Promise.resolve(left({ code: 'error_code', message: 'error_message' }));
            }
        }
        return Promise.resolve(left({ code: 'error_code', message: 'error_message' }));
    }

    updateLang(userId: string, langId: string): Promise<ApiResponse<OfficeLanguage>> {
        if (userId && langId) {
            const lang = officeLanguages.find((l) => l.id === langId);
            return Promise.resolve(right(lang?.language_iso2 as OfficeLanguage));
        }
        return Promise.resolve(left({ code: 'error_code', message: 'error_message' }));
    }

    getDefaultPreferences(country: string): Promise<ApiResponse<Preferences>> {
        if (country) {
            return Promise.resolve(
                right({
                    storageCost: '1.00',
                    marginEuro: '2.00',
                    marginPercentage: '3.00',
                    adminCosts: '4.00',
                    reviewCosts: '5.00',
                    warrantyFee1: '6.00',
                    warrantyFee2: '7.00',
                    warrantyFee3: '8.00',
                    interestRate: '9.00',
                    commercialExpenses: '10.00',
                }),
            );
        }
        return Promise.resolve(left({ code: 'error_code', message: 'error_message' }));
    }

    getPreferences(subscriptionId: number, country: string): Promise<ApiResponse<Preferences>> {
        if (this.preferences && subscriptionId && country) {
            return Promise.resolve(right(this.preferences));
        }
        return Promise.resolve(left({ code: 'error_code', message: 'error_message' }));
    }

    updatePreferences(
        subscriptionId: number,
        country: string,
        preferences: Preferences,
    ): Promise<ApiResponse<Preferences>> {
        if (preferences) {
            return Promise.resolve(right(preferences));
        }
        return Promise.resolve(left({ code: 'error_code', message: 'error_message' }));
    }

    feedWith(sampleUser: User, samplePreferences: Preferences) {
        this.user = sampleUser;
        this.preferences = samplePreferences;
    }
}
