import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TranslateProvider } from 'autobiz-translate';
import { auth0Credentials } from '../../../config';
import { setTokenUseCase } from '../../../hexagon/usecases/setToken/setTokenUseCase';
import { getUserInfoUseCase } from '../../../hexagon/usecases/getUserInfo/getUserInfoUseCase';
import { getUserFeedsUseCase } from '../../../hexagon/usecases/getUserFeeds/getUserFeedsUseCase';
import { getUserStatsUseCase } from '../../../hexagon/usecases/getUserStats/getUserStatsUseCase';
import { getUserSelector } from '../view-models-generators/userSelector';
import { userFeedsRefreshDelayInSeconds } from '../../../hexagon/config/appConfig';
import Error from './Error/Error';
import HelmetComponent from './HelmetComponent/HelmetComponent';

export const UserProvider = ({ children }: any) => {
    const { getIdTokenClaims, isAuthenticated, error } = useAuth0();
    const [time, setTime] = useState(Date.now());
    const { tokenStatus, user } = useSelector(getUserSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchClaims = async () => {
            const claims = await getIdTokenClaims();

            return claims;
        };

        if (isAuthenticated) {
            fetchClaims().then((c) => {
                if (c?.__raw) dispatch(setTokenUseCase(c.__raw));
            });
        }
    }, [dispatch, isAuthenticated, getIdTokenClaims]);

    useEffect(() => {
        if (tokenStatus === 'succeeded') {
            dispatch(getUserInfoUseCase());
        }
    }, [dispatch, tokenStatus]);

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), userFeedsRefreshDelayInSeconds);
        return () => {
            clearInterval(interval);
        };
    }, [dispatch, time]);

    const searchParams = new URLSearchParams(document.location.search);
    const urlLanguage = searchParams.get('language');

    useEffect(() => {
        if (tokenStatus === 'succeeded' && user.provider === 'AZ') {
            dispatch(getUserFeedsUseCase());
            dispatch(getUserStatsUseCase());
        }
    }, [dispatch, time, tokenStatus, user]);

    if (error) {
        return <Error />;
    }
    return (
        <>
            <HelmetComponent language={user.language} />
            <TranslateProvider
                projectName="office-app"
                stage={process.env.REACT_APP_ENV === 'prod' ? 'prod' : 'dev'}
                language={user.language || urlLanguage}
            >
                {children}
            </TranslateProvider>
        </>
    );
};

export const Auth0ProviderWithNavigate = ({ children }: any) => {
    const navigate = useNavigate();

    const { domain, clientId } = auth0Credentials;

    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: `${window.location.origin}/dashboard`,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <UserProvider>{children}</UserProvider>
        </Auth0Provider>
    );
};
