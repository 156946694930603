import { ThunkResult } from '../../../redux/configureStore';
import { UserDataGateway } from '../../gateways/userDataGateway.interface';
import * as actionCreators from './actionCreators';
import { UserInfoGateway } from '../../gateways/userInfoGateway.interface';

export const setTokenUseCase =
    (token: string): ThunkResult<void> =>
    async (
        dispatch,
        getState,
        {
            userInfoGateway,
            userDataGateway,
        }: { userInfoGateway: UserInfoGateway; userDataGateway: UserDataGateway },
    ) => {
        dispatch(actionCreators.Actions.userInfoTokenFetching());
        try {
            await userInfoGateway.setToken(token);
            await userDataGateway.setToken(token);
            if (token) {
                dispatch(actionCreators.Actions.userInfoTokenRetrieved());
            } else {
                dispatch(actionCreators.Actions.userInfoTokenFailed());
            }
        } catch (err) {
            dispatch(actionCreators.Actions.userInfoTokenFailed());
        }
    };
