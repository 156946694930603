import { User } from '../../../hexagon/models/userModel';
import { AppState, FetchStatus } from '../../../redux/appState';

export interface UserVM {
    status: FetchStatus;
    updateStatus: FetchStatus;
    tokenStatus: FetchStatus;
    isAuthenticated: boolean;
    user: User;
}

export const getUserSelector = (state: AppState): UserVM => ({
    status: state.auth.status,
    updateStatus: state.auth.updateStatus,
    tokenStatus: state.auth.tokenStatus,
    isAuthenticated: state.auth.tokenStatus === 'succeeded',
    user: state.auth.user,
});
