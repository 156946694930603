import React, { FunctionComponent } from 'react';
import { t } from 'autobiz-translate';
import { ReactComponent as OfficeLogo } from '../../../../assets/img/autobizOffice-logo.svg';
import './accessValidation.scss';

type TCurrentStep = 1 | 2 | 3;
type TAccessValidationWrapperProps = {
    currentStep?: TCurrentStep;
    title?: string;
    subtitle?: string;
    label?: string;
    className?: string;
    children: React.ReactNode;
};

const AccessValidationWrapper: FunctionComponent<TAccessValidationWrapperProps> = ({
    currentStep,
    title,
    subtitle,
    label,
    className,
    children,
}) => (
    <>
        <div className={`accessValidation-wrapper ${className}`}>
            <div className="accessValidation-container">
                <header>
                    <div className="logo">
                        <OfficeLogo />
                    </div>
                    {title && <h1>{title}</h1>}
                    {subtitle && <p>{subtitle}</p>}

                    {currentStep && (
                        <ul className={`onboarding-steps current-step-${currentStep}`}>
                            <li className={currentStep >= 1 ? 'current' : ''}>1</li>
                            <li className={currentStep >= 2 ? 'current' : ''}>2</li>
                            <li className={currentStep >= 3 ? 'current' : ''}>3</li>
                        </ul>
                    )}

                    {label && <p className="text-center mt-4">{label}</p>}
                </header>
                <>{children}</>

                <div className="help">
                    <hr />
                    <h3>{t('connection_help_title')}</h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t('connection_help_description') || '',
                        }}
                    />
                </div>
            </div>
        </div>
    </>
);

export default AccessValidationWrapper;
