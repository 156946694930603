import React from 'react';
import { AutobizLogo } from 'autobiz-strap';
import { t } from 'autobiz-translate';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row, CardHeader } from 'reactstrap';
import { ProductName } from '../../../../hexagon/models/productModel';
import { Feed } from '../../../../hexagon/models/userFeedsModel';
import { getUserFeedsSelector } from '../../view-models-generators/userFeedsSelectors';

interface IUserFeed {
    productName: ProductName;
    feed: Feed;
}

const UserFeed: React.FC<IUserFeed> = ({ productName, feed }) => {
    let color = '#000';

    if (productName === 'autobizTrade') color = '#00693b';
    if (productName === 'autobizCarcheck') color = '#f15a24';
    if (productName === 'autobizClic2Sell') color = '#006AB0';

    return (
        <Card className="h-100 mt-2">
            <CardHeader className="px-0 mx-3">
                <AutobizLogo
                    product={productName}
                    withBrandNameOnMobile
                    withBrandNameOnDesktop
                    direction="row"
                    lang="fr"
                />
            </CardHeader>
            <CardBody>
                <Row>
                    {Object.entries(feed.feed).map(([key, value]) => (
                        <Col key={key} xs={6} xl={3} className="my-2">
                            <a
                                className="databox"
                                href={value.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="font-weight-bold h4" style={{ color }}>
                                    {value.count}
                                </div>
                                <div>{t(`action_${key}`)}</div>
                            </a>
                        </Col>
                    ))}
                </Row>
            </CardBody>
        </Card>
    );
};
const UserFeeds: React.FC = () => {
    const { data } = useSelector(getUserFeedsSelector);

    return (
        <Row>
            <>
                {data && data.autobizCarcheck && (
                    <Col md={6} className="mb-3">
                        <UserFeed productName="autobizCarcheck" feed={data.autobizCarcheck} />
                    </Col>
                )}
                {data && data.autobizTrade && (
                    <Col md={6} className="mb-3">
                        <UserFeed productName="autobizTrade" feed={data.autobizTrade} />
                    </Col>
                )}
                {data && data.autobizClic2Sell && (
                    <Col md={6} className="mb-3">
                        <UserFeed productName="autobizClic2Sell" feed={data.autobizClic2Sell} />
                    </Col>
                )}
            </>
        </Row>
    );
};

export default UserFeeds;
