import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';
import { UserInfoGateway } from '../../gateways/userInfoGateway.interface';

export const setUserLangUseCase =
    (langId: string): ThunkResult<void> =>
    async (dispatch, getState, { userInfoGateway }: { userInfoGateway: UserInfoGateway }) => {
        const { id: userId } = getState().auth.user;
        const result = await userInfoGateway.updateLang(userId, langId);
        dispatch(actionCreators.Actions.userLangUpdating());
        if (isRight(result)) {
            dispatch(actionCreators.Actions.userLangSet(result.right));
        } else {
            dispatch(actionCreators.Actions.userLangFailed());
        }
    };
